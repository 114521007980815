import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllDossiers, getAssignedDossiers } from '../actions/paperwork';
import { SpacedRow } from '../ui/style';
import isAdvisor from '../utils/isAdvisor';
import DossierDropDown from './DossierDropdown';
import FileModalButton from './Modal/FileModalButton';

const Header = ({ currentSocket, title }) => {
    const isAdmin = useSelector((state) => state.auth.isAdmin);
    const role = useSelector((state) => state.auth.role);
    const userId = useSelector((state) => state.auth.id);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isAdmin) {
            dispatch(getAllDossiers());
        } else if (isAdvisor(role)) {
            dispatch(getAssignedDossiers(userId));
        }
    }, [dispatch, isAdmin, role, userId]);

    if (!currentSocket) { return null; }

    return (
        <>
            {(isAdmin || isAdvisor(role)) && <DossierDropDown />}
            <SpacedRow>
                {title}
                {!isAdvisor(role) && <FileModalButton currentSocket={currentSocket} />}
            </SpacedRow>
        </>
    );
};

Header.propTypes = {
    currentSocket: PropTypes.shape({}),
    title: PropTypes.node.isRequired,
};

export default Header;
