const initialState = {
    avatar: null,
    dossierId: null,
    email: null,
    firstName: null,
    id: null,
    isAdmin: null,
    lastName: null,
    phoneNumber: null,
    role: null,
};

const authReducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case 'SET_USER':

            return {
                ...state,
                avatar: payload.avatar,
                dossierId: payload.dossierId,
                email: payload.email,
                firstName: payload.firstName,
                id: payload.id,
                isAdmin: payload.isAdmin,
                lastName: payload.lastName,
                phoneNumber: payload.phoneNumber,
                role: payload.role,
            };
        default:
            return state;
    }
};

export default authReducer;
