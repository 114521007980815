/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getDossierInformations } from '../../actions/dossier';
import { Space } from '../../ui/style';
import formattedDate from '../../utils/formattedDate';
import isAdvisor from '../../utils/isAdvisor';
import { BriefText, Date, Image, MainContainer } from './style';
import briefImage from '../../assets/brief.png';

const Brief = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const role = useSelector((state) => state.auth.role);
    const dossierId = useSelector((state) => state.auth.dossierId);
    const selectedDossier = useSelector((state) => state.paperwork.selectedDossier);
    const dossier = useSelector((state) => state.dossier);

    useEffect(() => {
        if (!isAdvisor(role)) {
            dispatch(getDossierInformations(dossierId));
        }
    }, [dispatch, dossierId, role]);

    const date = isAdvisor(role) ? selectedDossier && selectedDossier.createdAt : dossier.createdAt;
    const brief = isAdvisor(role) ? selectedDossier && selectedDossier.brief : dossier.brief;
    const name = isAdvisor(role) ? selectedDossier && selectedDossier.name : dossier.name;

    return (
        isAdvisor(role) && !selectedDossier ? (
            <p>{t('documents.noDossierSelected')}</p>
        ) : (
            <>
                <Space px={80} />
                <MainContainer>
                    <Image>
                        <img alt={t('brief.image.alt')} src={briefImage} />
                    </Image>
                    <h3>{name}</h3>
                    <Date>{t('brief.date', { date: formattedDate(date, 'day') })}</Date>
                    <BriefText dangerouslySetInnerHTML={{ __html: brief, sanitize: true }} />
                </MainContainer>
            </>
        )
    );
};

export default Brief;
