import PropTypes from 'prop-types';
import React from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const MainInfo = styled.p`
  color: ${({ theme }) => theme.colors.primaryPink};
`;

const ConstraintsInfo = styled.p`
  white-space: pre-line;
  font-size: 0.8em;
  font-style: italic;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 20px;
`;

const DropZone = (props) => {
    const { t } = useTranslation();
    const { onDrop } = props;
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop(item, monitor) {
            if (onDrop) {
                onDrop(props, monitor);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = canDrop && isOver;

    return (
        <div ref={drop} {...props}>
            <MainInfo>{isActive ? t('dragndrop.drop') : t('dragndrop.drag')}</MainInfo>
            <ConstraintsInfo>{t('files.upload.constraints_info')}</ConstraintsInfo>
        </div>
    );
};

DropZone.propTypes = {
    onDrop: PropTypes.func.isRequired,
};

export default DropZone;
