import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getDossierDocuments } from '../../actions/dossier';
import { Punchline } from '../../ui/style';
import isAdvisor from '../../utils/isAdvisor';
import DocumentsCategoryBlock from './DocumentsCategoryBlock';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  display: none;
`;

const Documents = ({ dossierId, role }) => {
    const dispatch = useDispatch();
    const selectedDossierDocuments = useSelector((state) => state.paperwork.documents);
    const documents = useSelector((state) => state.dossier.documents);
    const { t } = useTranslation();
    const documentDeleteOverlayRef = useRef();

    useEffect(() => {
        if (dossierId) {
            dispatch(getDossierDocuments(dossierId, role));
        }
    }, [dispatch, dossierId, role]);

    if (isAdvisor(role) && null === dossierId) {
        return <p>{t('documents.noDossierSelected')}</p>;
    }

    const categories = isAdvisor(role) ? selectedDossierDocuments : documents;

    return (
        <>
            <Overlay ref={documentDeleteOverlayRef} />
            {categories.map((item) => (
                <DocumentsCategoryBlock
                    key={item.id}
                    category={item}
                    documentDeleteOverlayRef={documentDeleteOverlayRef}
                    dossierId={dossierId}
                    isAdvisor={isAdvisor(role)}
                />
            ))}
            <Punchline>{t('login.punchline')}</Punchline>
        </>
    );
};

Documents.propTypes = {
    dossierId: PropTypes.string,
    role: PropTypes.string.isRequired,
};

export default Documents;
