import i18n from '../i18n';

export const getRightTitle = (selectedDossier, titleTranslationKey, isAdmin = false) => {
    if (!isAdmin || null == selectedDossier) {
        return i18n.t(titleTranslationKey);
    }

    const clients = selectedDossier.clients.map((u) => `${u.firstName} ${u.lastName}`);

    return `${i18n.t(`${titleTranslationKey}.admin`)} ${clients.join(', ')}`;
};

export default getRightTitle;
