import PropTypes from 'prop-types';
import { reduce } from 'ramda';
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { DndProvider as DragnDropProvider } from 'react-dnd';
import { HTML5Backend as Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getUploadedFiles } from '../../actions/file';
import { emptyFileStatusList, uploadFile } from '../../actions/upload';
import IconButton from '../../ui/IconButton';
import { SmallPrimaryButton, SpacedRow, Spinner } from '../../ui/style';
import { getUniqueFiles } from '../../utils/upload';
import DroppedFilesList from './FileModal/DroppedFilesList';
import { ActionButton, Actions, BoldParagraph, StyledDropZone, StyledModal } from './style';
import CloseIcon from '../../assets/icons/clear.svg';

const FileModalButton = ({ currentSocket }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const dossierId = useSelector((state) => state.dossier.id);
    const userId = useSelector((state) => state.auth.id);
    const filesStatus = useSelector((state) => state.upload.filesStatus);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const conversationId = useSelector((state) => state.conversation.id);
    const userAvatar = useSelector((state) => state.auth.avatar || null);
    const role = useSelector((state) => state.auth.role);

    const addFilesWithDragnDrop = useCallback((item, monitor) => {
        if (monitor) {
            const droppedFiles = monitor.getItem().files;
            setFiles(getUniqueFiles(files, droppedFiles));
        }
    }, [files]);

    const addFilesWithFileSelector = useCallback((event) => {
        setFiles(getUniqueFiles(files, event.target.files));
    }, [files]);

    const openFileSelector = () => {
        fileInputRef.current.click();
    };

    const sendFiles = () => {
        const importDate = new Date();
        files.forEach((file) => {
            dispatch(uploadFile(userId, dossierId, file, importDate));
        });
    };

    const removeFile = useCallback((file) => {
        setFiles(files.filter((item) => file.name !== item.name));
    }, [files]);

    const isUploadPending = useMemo(() => reduce((acc, { status }) => acc || 'pending' === status, false, filesStatus), [filesStatus]);

    const isUploadStarted = useMemo(() => filesStatus.length > 0, [filesStatus]);

    const isUploadFinished = useMemo(() => filesStatus.length > 0 && !isUploadPending, [filesStatus, isUploadPending]);

    useEffect(() => {
        if (isUploadFinished) {
            let uploaded = 0;
            filesStatus.forEach((file) => {
                if ('uploaded' === file.status) {
                    uploaded++;
                }
            });
            if (uploaded > 0) {
                currentSocket.emit('join', conversationId);
                currentSocket.emit('sendMessage',
                    {
                        conversationId,
                        userAvatar,
                        userId,
                        message: t('files.upload.notification', { count: uploaded }),
                    });
            }
        }
    }, [conversationId, currentSocket, filesStatus, isUploadFinished, t, userAvatar, userId]);

    const toggleModal = useCallback(() => {
        if (!isUploadPending) {
            setIsOpen(!isOpen);
            if (isUploadFinished) {
                dispatch(getUploadedFiles(dossierId, role));
            }
        }

        if (!isOpen) {
            setFiles([]);
            dispatch(emptyFileStatusList());
        }
    }, [dispatch, dossierId, isOpen, isUploadPending, isUploadFinished, role]);

    return (
        <DragnDropProvider backend={Backend}>
            <SmallPrimaryButton onClick={toggleModal}>
                <span>{t('files.button.import')}</span>
            </SmallPrimaryButton>
            <StyledModal
                isOpen={isOpen}
                onBackgroundClick={toggleModal}
                onEscapeKeydown={toggleModal}
            >
                <SpacedRow>
                    <h2>{t('files.import.modal_title')}</h2>
                    {!isUploadPending && (
                        <IconButton onClick={toggleModal}>
                            <img alt={t('modal.button.close')} src={CloseIcon} />
                        </IconButton>
                    )}
                </SpacedRow>
                {!isUploadStarted && (
                    <>
                        <p>{t('files.upload.description')}</p>
                        <input
                            ref={fileInputRef}
                            name="file"
                            onChange={addFilesWithFileSelector}
                            style={{ display: 'none' }}
                            type="file"
                            multiple
                        />
                        <StyledDropZone onClick={openFileSelector} onDrop={addFilesWithDragnDrop} />
                    </>
                )}
                {isUploadPending && <p style={{ marginBottom: 0 }}>{t('files.upload.description.in_progress')}</p>}
                {isUploadFinished && <BoldParagraph>{t('files.upload.description.finished')}</BoldParagraph>}
                <DroppedFilesList files={files} onRemove={removeFile} />
                {isUploadFinished && (
                    <>
                        <BoldParagraph>{t('files.import.will_be_processed')}</BoldParagraph>
                        <BoldParagraph>{t('files.import.remarks_or_details')}</BoldParagraph>
                    </>
                )}
                <Actions>
                    <ActionButton
                        disabled={0 === files.length || isUploadPending}
                        onClick={() => (isUploadFinished ? toggleModal() : sendFiles())}
                    >
                        {!isUploadStarted && <span>{t('files.button.upload')}</span>}
                        {isUploadPending && (
                            <>
                                <Spinner />
                                <span>{t('files.button.upload.in_progress')}</span>
                            </>
                        )}
                        {isUploadFinished && <span>{t('files.button.upload.finished')}</span>}
                    </ActionButton>
                </Actions>
            </StyledModal>
        </DragnDropProvider>
    );
};

FileModalButton.propTypes = {
    currentSocket: PropTypes.shape({
        emit: PropTypes.func.isRequired,
    }).isRequired,
};

export default FileModalButton;
