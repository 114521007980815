/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { addMessage, getAllMessages } from '../../actions/conversation';
import IconButton from '../../ui/IconButton';
import { Space } from '../../ui/style';
import isAdvisor from '../../utils/isAdvisor';
import ContactInformations from './ContactInformations';
import MessagesBox from './MessagesBox';
import {
    MainContainer,
    RecipientContainer,
    GreyTitle,
    SendBox,
    BigInput,
    MessagesContainer,
} from './style';
import defaultAvatar from '../../assets/avatar.svg';
import sendIcon from '../../assets/icons/send.svg';

const Chat = ({ conversationId, currentSocket, selectedDossier }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const role = useSelector((state) => state.auth.role);
    const userId = useSelector((state) => state.auth.id);
    const dossier = useSelector((state) => state.dossier);
    const messagesList = useSelector((state) => state.conversation.messages);
    const userAvatar = useSelector((state) => state.auth.avatar || null);
    const [lastMessage, setLastMessage] = useState();

    useEffect(() => {
        if (conversationId) {
            dispatch(getAllMessages(conversationId));
        }
    }, [conversationId, dispatch, selectedDossier]);

    useEffect(() => {
        const chat = currentSocket;
        chat.emit('join', conversationId);
        chat.on('newMessage', (messageData) => {
            dispatch(addMessage(messageData));
        });

        return () => {
            chat.emit('leave', conversationId);
        };
    }, [conversationId, currentSocket, dispatch]);

    const sendMessage = async () => {
        await currentSocket.emit('sendMessage', { conversationId, userAvatar, userId, message: lastMessage });
        await setLastMessage('');
    };

    const saveMessage = (e) => {
        setLastMessage(e.target.value);
    };

    if (isAdvisor(role) && null === selectedDossier) {
        return <p>{t('chat.noDossierSelected')}</p>;
    }

    return (
        <>
            <Space px={80} />
            <MainContainer>
                <RecipientContainer>
                    <GreyTitle>{isAdvisor(role) ? t('chat.contact.customer') : t('chat.contact.counsels')}</GreyTitle>
                    {'client' === role ? (
                        dossier.employees.map((employee) => (
                            employee && (
                                <ContactInformations
                                    key={employee.id}
                                    avatar={employee.avatar || defaultAvatar}
                                    email={employee.email}
                                    name={`${employee.firstName} ${employee.lastName}`}
                                    phone={employee.phone}
                                    userRole={employee.role}
                                />
                            )
                        ))
                    ) : (
                        selectedDossier.clients.map((client) => (
                            <ContactInformations
                                key={client.userId}
                                avatar={defaultAvatar}
                                email={client.email}
                                name={`${client.firstName} ${client.lastName}`}
                                phone={client.phone}
                            />
                        ))
                    )}
                </RecipientContainer>
                <MessagesContainer>
                    <MessagesBox messagesList={messagesList} userId={userId} />
                    <SendBox>
                        <BigInput
                            onChange={(e) => saveMessage(e)}
                            type="text"
                            value={lastMessage}
                        />
                        <IconButton onClick={sendMessage} type="button">
                            <img alt={t('messages.alt.send')} src={sendIcon} />
                        </IconButton>
                    </SendBox>
                </MessagesContainer>
            </MainContainer>
        </>
    );
};

Chat.propTypes = {
    conversationId: PropTypes.string.isRequired,
    currentSocket: PropTypes.shape({
        emit: PropTypes.func.isRequired,
    }).isRequired,
    selectedDossier: PropTypes.shape({
        clients: PropTypes.arrayOf(
            PropTypes.shape({}).isRequired,
        ),
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
};

export default Chat;
