import i18n from '../i18n';

const PASSWORD_MIN_LENGTH = 6;

export const required = (value) => (!value ? i18n.t('error.empty.field') : null);
export const requiredTos = (value) => (!value ? i18n.t('error.empty.field.tos') : null);
export const requiredId = (value) => (!value ? i18n.t('error.empty.id') : null);
export const requiredPassword = (value) => (!value ? i18n.t('error.empty.password') : null);
export const passwordMinLength = (value) => (
    value && value.length < PASSWORD_MIN_LENGTH ? i18n.t('error.passwordTooShort', { min: PASSWORD_MIN_LENGTH }) : null
);
