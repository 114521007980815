/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoginLayout from '../../components/Layout/LoginLayout';
import LoginForm from '../../components/LoginForm';

const Login = ({ history }) => {
    const { t } = useTranslation();

    return (
        <LoginLayout title={t('login.title')}>
            <LoginForm history={history} />
        </LoginLayout>
    );
};

Login.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Login;
