import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import formattedDate from '../../utils/formattedDate';
import Message from './Message';
import { ReceptionBlock, OverflowContent } from './style';

const MessagesBox = ({ messagesList, userId }) => {
    const messagesEndRef = useRef(null);

    const scrollToBottom = useCallback(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messagesEndRef]);

    useEffect(() => {
        scrollToBottom();
    });

    if (!messagesList) { return null; }

    return (
        <ReceptionBlock>
            <OverflowContent>
                {messagesList && (
                    messagesList.map((message) => (
                        <Message
                            key={message.id}
                            author={message.author}
                            date={formattedDate(message.createdAt)}
                            textMessage={message.message}
                            type={(message.author && message.author.id === userId) || message.authorId === userId ? 'send' : 'receive'}
                        />
                    ))
                )}
                <div ref={messagesEndRef} />
            </OverflowContent>
        </ReceptionBlock>
    );
};

MessagesBox.propTypes = {
    messagesList: PropTypes.arrayOf(
        PropTypes.shape({
            avatar: PropTypes.string,
            createdAt: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    userId: PropTypes.string.isRequired,
};

export default MessagesBox;
