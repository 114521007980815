import i18n from '../i18n';

const formattedDate = (d, type) => {
    const date = new Date(d);
    const array = date.toString().split(' ');
    const weekDay = i18n.t(`date.${array[0]}`);
    const month = i18n.t(`date.${array[1]}`);
    const hour = array[4].split(':');

    if ('day' === type) {
        return `${weekDay} ${array[2]} ${month} ${array[3]}`;
    }

    return `${weekDay} ${array[2]} ${month} ${array[3]}, ${hour[0]}h${hour[1]}`;
};

export default formattedDate;
