import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilesBox, Paragraph } from '../../routes/Dashboard/style';
import { Row, SpacedRow } from '../../ui/style';
import formattedDate from '../../utils/formattedDate';
import isAdvisor from '../../utils/isAdvisor';
import checkIcon from '../../assets/icons/check.svg';
import errorIcon from '../../assets/icons/error.svg';

const DocumentsMetrics = ({ role, metrics, files, selectedDossier }) => {
    const { t } = useTranslation();

    return (
        <>
            <h2>{t('documents.files.list')}</h2>
            {isAdvisor(role) && !selectedDossier ? (
                <p>{t('documents.noDossierSelected')}</p>
            ) : (
                <>
                    {metrics && (
                        <>
                            <Row>
                                <img alt={t('documents.document.validated.alt')} src={checkIcon} />
                                <Paragraph type="validated">
                                    {`${metrics && metrics.documentsValidated}/${metrics.documentsTotal} ${t('documents.metrics.validated')}`}
                                </Paragraph>
                            </Row>
                            <Row>
                                <img alt={t('documents.document.missing')} src={errorIcon} />
                                <Paragraph type="missing">
                                    {`${metrics && metrics.documentsMissing} ${t('documents.metrics.missing')}`}
                                </Paragraph>
                            </Row>
                        </>
                    )}
                    <hr />
                    <h4>{t('documents.files.sent')}</h4>
                    <FilesBox>
                        {files.length > 0 ? files.map((file) => (
                            <SpacedRow key={file.id}>
                                <p>{file.name}</p>
                                <Paragraph style={{ fontStyle: 'italic' }}>{`(${formattedDate(file.createdAt)})`}</Paragraph>
                            </SpacedRow>
                        )) : (
                            <p>{t('documents.files.noFiles')}</p>
                        )}
                    </FilesBox>
                </>
            )}
        </>
    );
};

DocumentsMetrics.propTypes = {
    files: PropTypes.arrayOf(
        PropTypes.shape({
            createdAt: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    metrics: PropTypes.shape({
        documentsMissing: PropTypes.number.isRequired,
        documentsTotal: PropTypes.number.isRequired,
        documentsValidated: PropTypes.number.isRequired,
    }),
    role: PropTypes.string.isRequired,
    selectedDossier: PropTypes.shape({}),
};

export default DocumentsMetrics;
