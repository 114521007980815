import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserByToken } from '../actions/auth';

const mapDispatchToProps = { getUserByTokenAction: getUserByToken };

const requireTokenAuth = (ComposedComponent) => {
    class Auth extends Component {
        componentDidMount() {
            this.checkAuth();
        }

        componentDidUpdate() {
            this.checkAuth();
        }

        async checkAuth() {
            const { getUserByTokenAction, history, location } = this.props;
            const { username, token } = qs.parse(location.search, { ignoreQueryPrefix: true });

            try {
                await getUserByTokenAction(token, username);
            } catch (e) {
                history.push('/login');
            }
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    Auth.propTypes = {
        getUserByTokenAction: PropTypes.func.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
        }).isRequired,
    };

    return connect(null, mapDispatchToProps)(Auth);
};

export default requireTokenAuth;
