export const translateMimeTypeLabel = (mimeType) => {
    switch (mimeType) {
        case 'image/png':
            return 'Image PNG';
        case 'image/jpeg':
            return 'Image JPEG';
        case 'application/pdf':
            return 'Fichier PDF';
        case 'application/zip':
            return 'Archive ZIP';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'Fichier DOC/DOCX';
        default:
            return 'Fichier';
    }
};

const numberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
};

export const convertFileSize = (fileSize) => {
    const kilo = fileSize / 1000;
    if (1000 > kilo) {
        return `${kilo.toLocaleString('fr-FR', numberFormatOptions)}Ko`;
    }

    return `${(kilo / 1000).toLocaleString('fr-FR', numberFormatOptions)}Mo`;
};
