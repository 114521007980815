import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth';
import conversationReducer from './conversation';
import dossierReducer from './dossier';
import paperworkReducer from './paperwork';
import uploadReducer from './upload';

const rootReducer = combineReducers({
    auth: authReducer,
    conversation: conversationReducer,
    dossier: dossierReducer,
    form: formReducer,
    paperwork: paperworkReducer,
    upload: uploadReducer,
});

export default rootReducer;
