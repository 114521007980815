import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getConversationData } from '../../actions/conversation';
import { Punchline, TabsContainer, Tab } from '../../ui/style';
import isAdvisor from '../../utils/isAdvisor';
import Brief from '../Brief';
import Chat from '../Chat';

const FollowUp = ({ currentSocket }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(1);
    const selectedDossier = useSelector((state) => state.paperwork.selectedDossier);
    const clientDossierId = useSelector((state) => state.auth.dossierId) || null;
    const role = useSelector((state) => state.auth.role);
    const conversationId = useSelector((state) => state.conversation.id);
    const dispatch = useDispatch();

    useEffect(() => {
        setActiveTab(1);
        let dossierId;
        if (isAdvisor(role) && selectedDossier) {
            dossierId = selectedDossier.id;
        } else if (clientDossierId) {
            dossierId = clientDossierId;
        }
        if (dossierId) {
            dispatch(getConversationData(dossierId));
        }
    }, [clientDossierId, dispatch, role, selectedDossier]);

    return (
        <>
            <TabsContainer>
                <Tab isActive={1 === activeTab} onClick={() => setActiveTab(1)}>
                    <h3>{t('followUp.tab.brief')}</h3>
                </Tab>
                <Tab isActive={2 === activeTab} onClick={() => setActiveTab(2)}>
                    <h3>{t('followUp.tab.chat')}</h3>
                </Tab>
            </TabsContainer>
            { 1 === activeTab && <Brief /> }
            { 2 === activeTab && <Chat conversationId={conversationId} currentSocket={currentSocket} selectedDossier={selectedDossier} /> }
            <Punchline chat={2 === activeTab}>{t('login.punchline')}</Punchline>
        </>
    );
};

FollowUp.propTypes = {
    currentSocket: PropTypes.shape({}),
};

export default FollowUp;
