import PropTypes from 'prop-types';
import React from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import styled from 'styled-components';
import { Input } from '../ui/style';

export const Error = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primaryPink};
  width: 330px;
  margin-bottom: 0;
`;

const FormInput = ({ input, type, placeholder, meta: { touched, error }, ...props }) => (
    <>
        <Input
            {...input}
            {...props}
            placeholder={placeholder}
            type={type}
        />
        {touched && error && <Error>{error}</Error>}
    </>
);

FormInput.propTypes = {
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
};

export default FormInput;
