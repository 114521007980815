/* eslint-disable import/no-cycle */
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import React, { createContext, PureComponent, useContext } from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';

import createStore from './createStore';
import Routes from './Routes';
import './i18n';

const AppDataContext = createContext({});

export const useAppData = () => useContext(AppDataContext);

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Muli|Open+Sans&display=swap');

  body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    font-size: 14px;
    scrollbar-color: transparent transparent;

    h1 {
      font-family: 'Muli', sans-serif;
      font-size: 2.1em;
    }

    h3 {
      font-size: 1.3em;
    }

    h4 {
      font-size: 1.1em;
    }

    a {
      font-size: 1em;
    }
  }
`;

const theme = {
    colors: {
        blueGrey: '#95a1b7',
        primary: '#ee3800',
        primaryPink: '#c7135c',
        white: '#ffffff',
        black: '#12060e',
        whiteBlue: '#fafbfd',
        paleGrey: 'rgba(237, 242, 249, 0.56)',
        backgroundBlue: '#edf2f9',
        backgroundGreen: 'rgba(202, 238, 232, 0.5)',
        lightGrey: 'rgba(149, 161, 183, 0.34)',
        shadowGrey: 'rgba(0, 38, 94, 0.07)',
        borderColor: '#d1dcee',
        whiteGrey: '#acacac',
        whiteSmoke: '#f6f6f6',
        dateGrey: '#bbbbbb',
        metricGrey: '#afafaf',
        green: '#00aa8c',
        iconGrey: 'rgba(0, 0, 0, 0.54)',
    },
};

class App extends PureComponent {
    constructor(props) {
        super(props);

        this.history = createBrowserHistory();
        this.store = createStore({}, { history: this.history });
    }

    componentDidMount() {
        this.history.listen((location) => ReactGA.pageview(location.pathname));
    }

    render() {
        const { appData } = this.props;

        return (
            <AppDataContext.Provider value={appData}>
                <Provider store={this.store}>
                    <Router history={this.history}>
                        <ThemeProvider theme={theme}>
                            <GlobalStyles />
                            <ModalProvider>
                                <Routes />
                            </ModalProvider>
                        </ThemeProvider>
                    </Router>
                </Provider>
            </AppDataContext.Provider>
        );
    }
}

App.propTypes = {
    appData: PropTypes.shape({}),
};

App.defaultProps = {
    appData: {},
};

export default App;
