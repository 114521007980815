import styled from 'styled-components';
import { WhiteBox } from '../../ui/style';

export const MainContainer = styled(WhiteBox)`
  width: 60vw;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;

  h3 {
    margin-top: 0;
  }
`;

export const Date = styled.h4`
  color: ${({ theme }) => theme.colors.metricGrey};
  margin: 0;
`;

export const Image = styled.div`
  img {
    position: absolute;
    left: 50vw;
    top: 200px;
    width: 200px;
  }
`;

export const BriefText = styled.h4`
  font-weight: normal;
  line-height: 1.6;
`;
