import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser } from '../actions/auth';

const mapStateToProps = ({ auth: { id } }) => ({ id });

const mapDispatchToProps = { getUserAction: getUser };

const requireAuth = (ComposedComponent) => {
    class Auth extends Component {
        componentDidMount() {
            this.checkAuth();
        }

        componentDidUpdate() {
            this.checkAuth();
        }

        async checkAuth() {
            const { getUserAction, history, id } = this.props;

            if (!localStorage.getItem('signedIn')) {
                await history.push('/login');
            }
            if (!id && localStorage.getItem('signedIn')) {
                try {
                    await getUserAction();
                } catch (e) {
                    history.push('/login');
                }
            }
        }

        render() {
            const { id } = this.props;

            return id ? <ComposedComponent {...this.props} /> : null;
        }
    }

    Auth.propTypes = {
        getUserAction: PropTypes.func.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        id: PropTypes.string,
    };

    return connect(mapStateToProps, mapDispatchToProps)(Auth);
};

export default requireAuth;
