import PropTypes from 'prop-types';
import React from 'react';
import { ContactBox, BigAvatar, NameBox } from './style';

const ContactInformations = ({ avatar, email, name, phone, userRole }) => (
    <ContactBox>
        <NameBox>
            <BigAvatar>
                <img alt="avatar" src={avatar} />
            </BigAvatar>
            <div>
                <h4>{name}</h4>
                <p>{userRole}</p>
            </div>
        </NameBox>
        <p>{email}</p>
        <p>{phone}</p>
    </ContactBox>
);

ContactInformations.propTypes = {
    avatar: PropTypes.string,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    userRole: PropTypes.string,
};

export default ContactInformations;
