import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeValidationStatus, deleteDocument as deleteDocumentAction, getDocumentsMetrics } from '../../actions/document';
import { getDossierDocuments } from '../../actions/dossier';
import { getStatusColor, validated } from '../../utils/documents';
import EditionInput from './EditionInput';
import { Actions, Button, CancelButton, DeleteButton, EditButton, Label, Status, TbodyRow, Td } from './style';
import clearIcon from '../../assets/icons/clear.svg';
import deleteIconActive from '../../assets/icons/delete-active.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import editIcon from '../../assets/icons/edit.svg';

const DocumentItem = ({ dossierId, id, isAdvisor, name, deleteOverlayRef, status }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [editionMode, toggleEditionMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const role = useSelector((state) => state.auth.role);

    const changeDocumentValidation = async (documentId, validationStatus) => {
        const validate = validated(validationStatus);
        await dispatch(changeValidationStatus(documentId, validate));
        await dispatch(getDossierDocuments(dossierId, 'advisor'));
        await dispatch(getDocumentsMetrics(dossierId, role));
    };

    const deleteDocument = async () => {
        await dispatch(deleteDocumentAction(id));
        setDeleteMode(false);
        await dispatch(getDossierDocuments(dossierId, 'advisor'));
        await dispatch(getDocumentsMetrics(dossierId, role));
    };

    useEffect(() => {
        const overlay = deleteOverlayRef.current;
        overlay.style.display = deleteMode ? 'block' : 'none';
        overlay.onclick = () => setDeleteMode(false);
    }, [deleteMode, deleteOverlayRef]);

    return (
        <TbodyRow key={id}>
            <Td className="row">
                {editionMode ? (
                    <EditionInput
                        closeEditionMode={toggleEditionMode}
                        documentId={id}
                        dossierId={dossierId}
                        name={name}
                    />
                ) : (
                    <Label>{name}</Label>
                )}
                {isAdvisor && (
                    <EditButton onClick={() => toggleEditionMode(!editionMode)} type="button">
                        <img alt={t('documents.icon.edit')} src={editionMode ? clearIcon : editIcon} />
                    </EditButton>
                )}
            </Td>
            <Status color={getStatusColor(status)}>
                {t(`documents.document.${status}`)}
            </Status>
            {isAdvisor && (
                <Actions>
                    {!validated(status) ? (
                        <CancelButton onClick={() => changeDocumentValidation(id, status)} type="button">
                            <span>
                                {t('documents.document.cancel')}
                            </span>
                        </CancelButton>
                    ) : (
                        <Button
                            onClick={() => changeDocumentValidation(id, status)}
                            status={status}
                            type="button"
                        >
                            <span>
                                {t('documents.document.validate')}
                            </span>
                        </Button>
                    )}
                    {!deleteMode ? (
                        <DeleteButton onClick={() => setDeleteMode(true)} title={t('documents.document.delete.title')} type="button">
                            <img alt={t('documents.document.delete.alt')} src={deleteIcon} />
                        </DeleteButton>
                    ) : (
                        <DeleteButton className="delete" onClick={deleteDocument} type="button">
                            <img alt={t('documents.document.delete.alt')} src={deleteIconActive} title={t('documents.document.confirm.title')} />
                        </DeleteButton>
                    )}
                </Actions>
            )}
        </TbodyRow>
    );
};

DocumentItem.propTypes = {
    deleteOverlayRef: PropTypes.shape({
        current: PropTypes.shape({
            onclick: PropTypes.func,
            style: PropTypes.shape({
                display: PropTypes.string,
            }),
        }).isRequired,
    }).isRequired,
    dossierId: PropTypes.string,
    id: PropTypes.string.isRequired,
    isAdvisor: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
};

export default DocumentItem;
