import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateDocumentName } from '../../actions/document';
import { getDossierDocuments } from '../../actions/dossier';
import { EditInput, SaveButton } from './style';
import saveIcon from '../../assets/icons/save.svg';

const EditionInput = ({ documentId, dossierId, name, closeEditionMode }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [documentName, setDocumentName] = useState(name);

    const saveInput = (e) => {
        setDocumentName(e.target.value);
    };

    const sendModification = async () => {
        await dispatch(updateDocumentName(documentId, documentName));
        await dispatch(getDossierDocuments(dossierId, 'advisor'));
        closeEditionMode(false);
    };

    return (
        <>
            <EditInput
                onChange={saveInput}
                onKeyPress={(e) => { if (13 === e.charCode) { sendModification(); } }}
                placeholder={name}
                type="text"
                value={documentName}
            />
            <SaveButton onClick={sendModification} type="button">
                <img alt={t('documents.name.save')} src={saveIcon} />
            </SaveButton>
        </>
    );
};

EditionInput.propTypes = {
    closeEditionMode: PropTypes.func.isRequired,
    documentId: PropTypes.string.isRequired,
    dossierId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default EditionInput;
