import * as Sentry from '@sentry/browser';
import { SubmissionError } from 'redux-form';
import Errors from '../constants/errors.json';
import { GET, POST } from '../utils/http';

const setUser = ({ user }) => ({
    type: 'SET_USER',
    ...user,
});

export const login = (values) => async (dispatch) => {
    try {
        const user = await POST('/api/auth/login', values);
        localStorage.setItem('signedIn', 'ok');
        await dispatch(setUser(user));
    } catch (e) {
        if ('Unauthorized' === e.message) {
            throw new SubmissionError({ _error: Errors.unauthorized });
        } else {
            Sentry.captureException(e);
            throw new SubmissionError({ _error: Errors.serverError });
        }
    }
};

export const getUser = () => async (dispatch) => {
    try {
        const user = await GET('/api/auth/user');

        return dispatch(setUser(user));
    } catch (e) {
        Sentry.captureException(e);
        throw new SubmissionError({ _error: Errors.serverError });
    }
};

export const getUserByToken = (accessToken, username) => async (dispatch) => {
    try {
        const user = await GET(`/api/auth/user-by-token?token=${accessToken}&username=${username}`);

        return dispatch(setUser(user));
    } catch (e) {
        Sentry.captureException(e);
        throw new SubmissionError({ _error: Errors.serverError });
    }
};

export const logout = () => async () => {
    try {
        await GET('/api/auth/logout');
        localStorage.removeItem('signedIn');
    } catch (e) {
        console.warn(e);
    }
};

export const updatePassword = (values) => async (dispatch) => {
    const { password, passwordRepeat } = values;
    if (password !== passwordRepeat) {
        throw new SubmissionError({ _error: Errors.passwordsMustMatch });
    }

    try {
        const user = await POST('/api/auth/update-password', values);
        localStorage.setItem('signedIn', 'ok');
        await dispatch(setUser(user));
    } catch (e) {
        if ('Unauthorized' === e.message) {
            throw new SubmissionError({ _error: Errors.unauthorized });
        } else {
            Sentry.captureException(e);
            throw new SubmissionError({ _error: Errors.serverError });
        }
    }
};
