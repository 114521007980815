import styled from 'styled-components';
import Modal from 'styled-react-modal';
import { Row, SmallPrimaryButton } from '../../ui/style';
import DroppedFile from './FileModal/DroppedFile';
import DropZone from './FileModal/DropZone';

export const StyledModal = Modal.styled`
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0;

  p {
    line-height: 1.5em;
  }
`;

export const StyledDropZone = styled(DropZone)`
  height: 60px;
  background-color: ${({ theme }) => theme.colors.whiteSmoke};
  border: 2px solid ${({ theme }) => theme.colors.primaryPink};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 40px 50px;
  text-align: center;

  p {
    margin: 0;
  }
`;

export const Actions = styled(Row)`
  margin: 10px 0 10px 0;
  align-items: baseline;
  justify-content: flex-end;
`;

export const ActionButton = styled(SmallPrimaryButton)`
  margin: 0;

  &:disabled {
    cursor: default;
    opacity: 0.8;
  }

  span {
    font-weight: normal;
    font-size: 1.1em;
  }
`;

export const StyledDroppedFile = styled(DroppedFile)`
  display: flex;
  justify-content: space-between;

  p {
    color: ${({ theme }) => theme.colors.black};
    margin: 6px 0;
  }

  button {
    color: ${({ theme }) => theme.colors.primaryPink};
    height: 30px;
    padding: 1px;
  }
`;

export const BoldParagraph = styled.p`
  margin-bottom: 0;
  font-weight: bold;
`;
