import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { convertFileSize, translateMimeTypeLabel } from '../../../translations/file';
import IconButton from '../../../ui/IconButton';
import { FileSpinner } from '../../../ui/style';
import CloseIcon from '../../../assets/icons/delete.svg';
import ErrorIcon from '../../../assets/icons/error.svg';
import SuccessIcon from '../../../assets/icons/success.svg';

const label = (file) => `${translateMimeTypeLabel(file.type)} "${file.name}" (${convertFileSize(file.size)})`;

const Status = styled.div`
  display: flex;

  p.message {
    margin-right: 10px;
    font-style: italic;
    font-size: 0.9em;
    color: gray;

    &.pending {
      margin-right: 34px;
    }
  }
`;

const DroppedFile = ({ className, file, onRemove }) => {
    const { t } = useTranslation();
    const filesStatus = useSelector((state) => state.upload.filesStatus);
    const fileStatus = useMemo(() => filesStatus.find(({ name }) => name === file.name), [file, filesStatus]);

    return (
        <li key={file.name} className={className}>
            <p>{label(file)}</p>
            {!fileStatus && (
                <IconButton onClick={() => onRemove(file)}>
                    <img alt={t('file.remove.alt')} src={CloseIcon} />
                </IconButton>
            )}
            {fileStatus && (
                <Status>
                    {'error' === fileStatus.status && (
                        <>
                            <p className="message">{t('file.upload.status.error')}</p>
                            <img alt={t('file.upload.error.alt')} src={ErrorIcon} title={t('file.upload.error', { error: fileStatus.error })} />
                        </>
                    )}
                    {'pending' === fileStatus.status && (
                        <>
                            <p className="message pending">{t('file.upload.status.pending')}</p>
                            <FileSpinner />
                        </>
                    )}
                    {'uploaded' === fileStatus.status && (
                        <>
                            <p className="message">{t('file.upload.status.success')}</p>
                            <img alt={t('file.upload.success.alt')} src={SuccessIcon} title={t('file.upload.success.alt')} />
                        </>
                    )}
                </Status>
            )}
        </li>
    );
};

DroppedFile.propTypes = {
    className: PropTypes.string,
    file: PropTypes.shape({
        name: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default DroppedFile;
