import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Row } from '../../ui/style';
import { getDocumentStatus } from '../../utils/documents';
import CreationInput from './CreationInput';
import DocumentItem from './DocumentItem';
import { AddButton, CategoryBox, Length, Table, Thead, TheadRow, Th, Tbody, Td, TbodyRow } from './style';
import addIcon from '../../assets/icons/add.svg';

const DocumentsCategoryBlock = ({ category, dossierId, isAdvisor, documentDeleteOverlayRef }) => {
    const { t } = useTranslation();
    const [formattedDocuments, setFormattedDocuments] = useState();
    const [creationMode, openCreationMode] = useState(false);
    const paperwork = useSelector((state) => state.paperwork || null);
    const { documents, id, name } = category;

    useEffect(() => {
        if (documents) {
            const documentsList = documents.map((document) => {
                const status = getDocumentStatus(document.sentByClient, document.received);

                return {
                    id: document.id,
                    name: document.name,
                    status,
                };
            });
            setFormattedDocuments(documentsList);
        }
    }, [documents]);

    if (!formattedDocuments) { return null; }

    return (
        <CategoryBox>
            <Row>
                <h3>{name}</h3>
                <Length>{`(${formattedDocuments.length})`}</Length>
            </Row>
            <Table>
                <Thead>
                    <TheadRow>
                        <Th className="text-left">{t('documents.document.name')}</Th>
                        <Th className="status">{t('documents.document.status')}</Th>
                        {isAdvisor && <Th className="actions">{t('documents.document.actions')}</Th>}
                    </TheadRow>
                </Thead>
                <Tbody>
                    {formattedDocuments.map((document) => (
                        <DocumentItem
                            key={document.id}
                            deleteOverlayRef={documentDeleteOverlayRef}
                            dossierId={isAdvisor ? paperwork.selectedDossier.id : null}
                            id={document.id}
                            isAdvisor={isAdvisor}
                            name={document.name}
                            status={document.status}
                        />
                    ))}
                    {isAdvisor && !creationMode && (
                        <TbodyRow>
                            <Td>
                                <AddButton onClick={() => openCreationMode(!creationMode)} type="button">
                                    <img alt={t('documents.document.add.alt')} src={addIcon} />
                                    <span>
                                        {t('documents.document.add.document')}
                                    </span>
                                </AddButton>
                            </Td>
                        </TbodyRow>
                    )}
                    {creationMode && (
                        <TbodyRow>
                            <Td>
                                <CreationInput
                                    categoryId={id}
                                    dossierId={dossierId}
                                    openCreationMode={openCreationMode}
                                />
                            </Td>
                        </TbodyRow>
                    )}
                </Tbody>
            </Table>
        </CategoryBox>
    );
};

DocumentsCategoryBlock.propTypes = {
    category: PropTypes.shape({
        documents: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                received: PropTypes.bool.isRequired,
                sentByClient: PropTypes.bool.isRequired,
            }).isRequired,
        ).isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    documentDeleteOverlayRef: PropTypes.shape({}).isRequired,
    dossierId: PropTypes.string.isRequired,
    isAdvisor: PropTypes.bool.isRequired,
};

export default DocumentsCategoryBlock;
