import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getRightTitle } from '../utils/wording';

const Title = ({ selectedDossier, currentTab }) => {
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    const getTitleTranslationRootKey = (tabName) => {
        switch (tabName) {
            case 'documents':
            case 'diagnostic':
                return tabName;
            case 'follow-up':
            default:
                return 'followUp';
        }
    };

    return (
        <h1>
            {getRightTitle(selectedDossier, `${getTitleTranslationRootKey(currentTab)}.title`, isAdmin)}
        </h1>
    );
};

Title.propTypes = {
    currentTab: PropTypes.string.isRequired,
    selectedDossier: PropTypes.shape({
        clients: PropTypes.arrayOf(
            PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
            }),
        ),
    }),
};

Title.defaultProps = {
    selectedDossier: null,
};

export default Title;
