/* eslint-disable import/no-cycle */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import requireAuth from './hoc/requireAuth';
import requireTokenAuth from './hoc/requireTokenAuth';
import Dashboard from './routes/Dashboard';
import Login from './routes/Login';
import Password from './routes/Password';
import Tos from './routes/Tos/Tos';

const Routes = () => (
    <Switch>
        <Route component={Login} path="/login" exact />
        <Route component={requireTokenAuth(Password)} path="/update-password" exact />
        <Route component={requireAuth(Dashboard)} path="/dashboard/:tab" exact />
        <Route component={Tos} path="/tos" exact />
        <Redirect to="/login" />
    </Switch>
);

export default Routes;
