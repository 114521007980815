/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Field, Form, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { login } from '../../actions/auth';
import { useAppData } from '../../App';
import { Column, PrimaryButton, Space, Text } from '../../ui/style';
import { requiredId, requiredPassword } from '../../utils/validation';
import Input, { Error } from '../Input';
import driftIcon from '../../assets/drift-icon.png';

const PasswordText = styled.p`
  color: ${(props) => props.theme.colors.blueGrey};
  width: 340px;
  line-height: 1.8;
`;

const LoginForm = ({ handleSubmit, error }) => {
    const { landingHost } = useAppData();
    const { t } = useTranslation();

    return (
        <Column>
            <Form onSubmit={handleSubmit}>
                <Space px={30} />
                <Text>
                    {t('login.id')}
                </Text>
                <Field
                    component={Input}
                    name="id"
                    placeholder="fabien.dupont"
                    type="text"
                    validate={requiredId}
                />
                <Space px={30} />
                <Text>
                    {t('login.password')}
                </Text>
                <Field
                    component={Input}
                    name="password"
                    placeholder="********"
                    type="password"
                    validate={requiredPassword}
                />
                <Space px={15} />
                <PasswordText>
                    {t('login.forgot.password')}
                    &nbsp;
                    <a href={`https://${landingHost}`} rel="noopener noreferrer" target="_blank">{t('login.forgot.password.link')}</a>
                    {t('login.forgot.password2')}
                    <img
                        alt="drift"
                        src={driftIcon}
                        style={{ width: '30px', position: 'absolute', marginLeft: '10px' }}
                    />
                </PasswordText>
                {error && (
                    <>
                        <Space px={10} />
                        <Error>
                            {t(`error.${error}`)}
                        </Error>
                    </>
                )}
                <Space px={30} />
                <PrimaryButton type="submit">
                    <p>{t('login.login')}</p>
                </PrimaryButton>
                <Space px={15} />
                <Link to="/tos">{t('tos.link.title')}</Link>
            </Form>
        </Column>
    );
};

LoginForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'login',
    onSubmit: async (values, dispatch) => {
        await dispatch(login(values));
    },
    onSubmitSuccess: async (result, dispatch, props) => {
        await props.history.push('/dashboard/follow-up');
    },
})(LoginForm);
