import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Space } from '../../ui/style';
import { Error } from '../Input';

const InputCheck = styled.input`
  margin-right: 10px;
`;

const TosComponent = ({ input, meta: { error, touched } }) => {
    const { t } = useTranslation();

    return (
        <div>
            <InputCheck type="checkbox" {...input} />
            {t('tos.link.accept')}
            &nbsp;
            <Link rel="noopener noreferrer" target="_blank" to="/tos">{t('tos.link.accept.link')}</Link>
            {error && touched && (
                <>
                    <Space px={10} />
                    <Error>
                        {t(error)}
                    </Error>
                </>
            )}
        </div>

    );
};

TosComponent.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.string.isRequired,
    }),
    meta: PropTypes.shape({
        error: PropTypes.string,
        touched: PropTypes.bool,
    }),
};

export default TosComponent;
