import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, reduxForm } from 'redux-form';
import { updatePassword } from '../../actions/auth';
import { Column, PrimaryButton, Space, Text } from '../../ui/style';
import { requiredTos, requiredPassword, passwordMinLength } from '../../utils/validation';
import Input, { Error } from '../Input';
import TosComponent from '../Tos';

const PasswordForm = ({ handleSubmit, error }) => {
    const { t } = useTranslation();

    return (
        <Column>
            <Form onSubmit={handleSubmit}>
                <Field
                    component={Input}
                    name="username"
                    type="hidden"
                />
                <Space px={30} />
                <Text>
                    {t('password.password')}
                </Text>
                <Field
                    component={Input}
                    name="password"
                    placeholder="******"
                    type="password"
                    validate={[requiredPassword, passwordMinLength]}
                />
                <Space px={30} />
                <Text>
                    {t('password.password_repeat')}
                </Text>
                <Field
                    component={Input}
                    name="passwordRepeat"
                    placeholder="******"
                    type="password"
                    validate={[requiredPassword, passwordMinLength]}
                />
                <Space px={15} />
                {error && (
                    <>
                        <Space px={10} />
                        <Error>
                            {t(`error.${error}`)}
                        </Error>
                    </>
                )}
                <Field
                    component={TosComponent}
                    name="acceptTos"
                    validate={requiredTos}
                />
                <Space px={70} />
                <PrimaryButton type="submit">
                    <p>{t('password.update')}</p>
                </PrimaryButton>
            </Form>
        </Column>
    );
};

PasswordForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'update-password',
    onSubmit: async (values, dispatch) => {
        await dispatch(updatePassword(values));
    },
    onSubmitSuccess: async (result, dispatch, props) => {
        await props.history.push('/dashboard/follow-up');
    },
})(PasswordForm);
