import PropTypes from 'prop-types';
import React from 'react';
import { StyledDroppedFile } from '../style';

const DroppedFilesList = ({ files, onRemove }) => {
    if (0 === files.length) {
        return null;
    }

    return (
        <ul style={{ paddingLeft: 0 }}>
            {files.map((file) => <StyledDroppedFile key={file.name} file={file} onRemove={onRemove} />)}
        </ul>
    );
};

DroppedFilesList.propTypes = {
    files: PropTypes.arrayOf(PropTypes.shape({})),
    onRemove: PropTypes.func.isRequired,
};

DroppedFilesList.defaultProps = {
    files: [],
};

export default DroppedFilesList;
