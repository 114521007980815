export const getDocumentStatus = (sentByClient, received) => {
    if (received) {
        return 'validated';
    }
    if (sentByClient) {
        return 'waitingForValidation';
    }

    return 'missing';
};

export const getStatusColor = (status) => {
    switch (status) {
        case 'missing':
            return '#12060e';
        case 'waitingForValidation':
            return '#ffab01';
        default:
            return '#00aa8c';
    }
};

export const validated = (validationStatus) => {
    if ('validated' === validationStatus) {
        return false;
    }

    return true;
};
