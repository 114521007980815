const initialState = {
    brief: null,
    createdAt: null,
    id: null,
    documents: [],
    documentsMetrics: null,
    employees: [],
    files: [],
    name: null,
};

const dossierReducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case 'SET_DOSSIER':
            return {
                ...state,
                id: payload.id,
                employees: payload.employees,
            };
        case 'SET_DOSSIER_DOCUMENTS':
            return {
                ...state,
                documents: payload.documents,
            };
        case 'SET_DOCUMENTS_METRICS':
            return {
                ...state,
                documentsMetrics: payload.metrics,
            };
        case 'SET_DOSSIER_UPLOADED_FILES':
            return {
                ...state,
                files: payload.files,
            };
        case 'SET_DOSSIER_INFORMATIONS':
            return {
                ...state,
                brief: payload.informations.brief,
                createdAt: payload.informations.createdAt,
                name: payload.informations.name,
            };
        default:
            return state;
    }
};

export default dossierReducer;
