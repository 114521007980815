/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-filename-extension */
import * as Sentry from '@sentry/browser';
import React from 'react';
import deepForceUpdate from 'react-deep-force-update';
import { render } from 'react-dom';
import ReactGA from 'react-ga';
import App from './App';
import ErrorBoundary from './ErrorBoundary';

const container = document.getElementById('root');

const { googleAnalyticsTag, landingHost, sentryDsn, sentryEnvironment } = container.dataset;

Sentry.init({
    dsn: sentryDsn,
    environment: sentryEnvironment,
});

const appData = {
    googleAnalyticsTag,
    landingHost,
};

ReactGA.initialize(appData.googleAnalyticsTag);
let appInstance = null;

const renderApp = () => {
    try {
        let appElement = <App appData={appData} />;

        if (process.env.NODE_ENV !== 'development') {
            appElement = (
                <ErrorBoundary>
                    <App appData={appData} />
                </ErrorBoundary>
            );
        }

        // render it
        appInstance = render(appElement, container);
    } catch (error) {
        if ('development' === process.env.NODE_ENV) {
            throw error;
        }
        console.error(error);
    }
};

renderApp();

// Enable Hot Module Replacement (HMR)
if (module.hot) {
    module.hot.accept('./App', () => {
        if (appInstance && appInstance.updater.isMounted(appInstance)) {
            // Force-update the whole tree, including components that refuse to update
            deepForceUpdate(appInstance);
        }

        renderApp();
    });
}
