import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import LoginLayout from '../../components/Layout/LoginLayout';
import PasswordForm from '../../components/PasswordForm';

const Password = ({ history }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { username } = qs.parse(location.search, { ignoreQueryPrefix: true });

    return (
        <LoginLayout title={t('password.title')}>
            <PasswordForm history={history} initialValues={{ username }} />
        </LoginLayout>
    );
};

Password.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Password;
