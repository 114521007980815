import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDossier } from '../actions/paperwork';
import { StyledDropdown } from '../ui/style';

const DossierDropDown = () => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(null);
    const dossiersList = useSelector((state) => state.paperwork.dossiers);
    const dispatch = useDispatch();

    const options = [];

    if (dossiersList) {
        Object.entries(dossiersList).forEach(([key, object]) => {
            const clientName = object.clients.map((c) => `${c.firstName} ${c.lastName}`);
            options.push({ value: key, label: `${object.name} / ${clientName.join(', ')}`, dossierId: object.id });
        });
    }

    const onSelect = (option) => {
        setSelectedOption(option);
        dispatch(setSelectedDossier(dossiersList[option.value]));
    };

    return (
        <StyledDropdown
            arrowClosed={<span className="arrow-closed" />}
            onChange={(option) => onSelect(option)}
            options={options}
            placeholder={t('dashboard.dropdown.chooseClient')}
            value={selectedOption}
        />
    );
};

export default DossierDropDown;
