import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import io from 'socket.io-client';
import { logout as logoutAction } from '../../actions/auth';
import { getDocumentsMetrics } from '../../actions/document';
import { getDossierAdvisors } from '../../actions/dossier';
import { getUploadedFiles } from '../../actions/file';
import Documents from '../../components/Documents';
import DocumentsMetrics from '../../components/Documents/DocumentsMetrics';
import FollowUp from '../../components/FollowUp';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { Column } from '../../ui/style';
import isAdvisor from '../../utils/isAdvisor';
import {
    DocumentsContainer,
    MainContainer,
    Sidebar,
    TabsContainer,
    Tab,
    TabText,
    ContentContainer,
} from './style';
import LogoMini from '../../assets/diviz-logo-mini.svg';
import FilesIconFaded from '../../assets/icons/docs-inactive.svg';
import FilesIcon from '../../assets/icons/docs.svg';
import FollowUpIconFaded from '../../assets/icons/followUp-inactive.svg';
import FollowUpIcon from '../../assets/icons/followUp.svg';
import logoutIcon from '../../assets/icons/logout.svg';

const Dashboard = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('follow-up');
    const history = useHistory();
    const dispatch = useDispatch();
    const isAdmin = useSelector((state) => state.auth.isAdmin);
    const role = useSelector((state) => state.auth.role);
    const clientDossierId = useSelector((state) => state.auth.dossierId);
    const dossiersList = useSelector((state) => state.paperwork.dossiers);
    const selectedDossier = useSelector((state) => state.paperwork.selectedDossier);
    const documentsMetrics = useSelector((state) => state.dossier.documentsMetrics);
    const selectedDossierDocumentsMetrics = useSelector((state) => state.paperwork.documentsMetrics);
    const selectedDossierFiles = useSelector((state) => state.paperwork.files);
    const clientFiles = useSelector((state) => state.dossier.files);
    const metrics = isAdvisor(role) ? selectedDossierDocumentsMetrics : documentsMetrics;
    const dossierId = isAdvisor(role) && selectedDossier ? selectedDossier.id : clientDossierId;
    const files = isAdvisor(role) ? selectedDossierFiles : clientFiles;
    const socket = useRef();

    useEffect(() => {
        socket.current = io.connect();
        if ('client' === role) {
            dispatch(getDossierAdvisors(dossierId));
        }

        if (dossierId) {
            dispatch(getDocumentsMetrics(dossierId, role));
            dispatch(getUploadedFiles(dossierId, role));
        }
    }, [dispatch, dossierId, role]);

    const goTo = (destination) => {
        setActiveTab(destination);
        history.push(`/dashboard/${destination}`);
    };

    const logout = async () => {
        try {
            await dispatch(logoutAction());
            history.push('/');
        } catch (e) {
            console.warn(e);
        }
    };

    if (isAdmin && null === dossiersList) { return null; }

    const title = <Title currentTab={activeTab} selectedDossier={selectedDossier} />;
    const currentDossier = isAdvisor(role) && selectedDossier ? selectedDossier.id : dossierId;

    return (
        <MainContainer>
            <Sidebar>
                <img alt="logo diviz" src={LogoMini} style={{ width: '53px', marginTop: '43px' }} />
                <TabsContainer>
                    <Tab isActive={'follow-up' === activeTab} onClick={() => goTo('follow-up')}>
                        <img
                            alt={t('dashboard.alt.followUp')}
                            src={'follow-up' === activeTab ? FollowUpIcon : FollowUpIconFaded}
                        />
                        <TabText>
                            {t('dashboard.followUp')}
                        </TabText>
                    </Tab>
                    <Tab isActive={'documents' === activeTab} onClick={() => goTo('documents')}>
                        <img
                            alt={t('dashboard.alt.documents')}
                            src={'documents' === activeTab ? FilesIcon : FilesIconFaded}
                        />
                        <TabText>
                            {t('dashboard.documents')}
                        </TabText>
                    </Tab>
                    <Tab onClick={logout} style={{ marginTop: '100px' }}>
                        <img
                            alt={t('dashboard.alt.logout')}
                            src={logoutIcon}
                        />
                        <TabText>
                            {t('dashboard.logout')}
                        </TabText>
                    </Tab>
                    <Link to="/tos">{t('tos.link')}</Link>
                </TabsContainer>
            </Sidebar>
            <ContentContainer>
                <Column style={{ width: '65%' }}>
                    <Header currentSocket={socket.current} title={title} />
                    {'follow-up' === activeTab && <FollowUp currentSocket={socket.current} />}
                    {'documents' === activeTab && (
                        <Documents
                            dossierId={currentDossier}
                            role={role}
                        />
                    )}
                </Column>
                <DocumentsContainer>
                    <DocumentsMetrics
                        dossierId={dossierId}
                        files={files}
                        metrics={metrics}
                        role={role}
                        selectedDossier={selectedDossier}
                    />
                </DocumentsContainer>
            </ContentContainer>
        </MainContainer>
    );
};

export default Dashboard;
