import styled from 'styled-components';
import { WhiteBox, Row, Column, TextArea } from '../../ui/style';

export const MainContainer = styled(WhiteBox)`
  width: 60vw;
  display: flex;
  flex-direction: row;
`;

export const RecipientContainer = styled(Column)`
  width: 30%;
  border-right: solid 2px ${(props) => props.theme.colors.borderColor};
  padding: 0 20px;
`;

export const MessagesContainer = styled(Column)`
  width: 70%;
  height: 100%;
  padding: 30px;
`;

export const GreyTitle = styled.h4`
  color: ${(props) => props.theme.colors.whiteGrey};
  margin-top: 20px;
`;

export const ContactBox = styled.div`
  background-color: ${(props) => props.theme.colors.whiteSmoke};
  width: 90%;
  min-width: 200px;
  height: 130px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;

  p {
    font-weight: normal;
    font-size: 1em;
    margin: 5px 0;

    @media (max-width: 1710px) {
      font-size: 0.8em;
    }
  }
`;

export const NameBox = styled(Row)`
  align-items: center;
  margin-bottom: 25px;

  p,
  h4 {
    margin: 0;
  }
`;

export const Avatar = styled.div`
  margin: auto 0;

  img {
    width: 29px;
    height: 29px;
    border-radius: 50%;
  }
`;

export const BigAvatar = styled.div`
  margin-right: 30px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }
`;

export const MessageBlock = styled(Column)`
  margin-bottom: 30px;
  align-items: ${(props) => ('send' === props.type ? 'flex-end' : 'flex-start')};
`;

export const MessageBox = styled.div`
  width: 100%;
  max-width: 650px;
  word-break: break-all;
  height: auto;
  border-radius: 15px;
  padding: 20px;
  margin: 0 20px;
  background-color: ${(props) => ('receive' === props.type ? props.theme.colors.backgroundBlue : props.theme.colors.backgroundGreen)};
`;

export const ReceptionBlock = styled(Column)`
  width: 100%;
  justify-content: flex-end;
  height: 550px;
  overflow: auto;
`;

export const OverflowContent = styled.div`
  height: 200px;
`;

export const SendBox = styled(Row)`
  height: 60px;
  width: 100%;
  align-items: center;
`;

export const BigInput = styled(TextArea)`
  width: 100%;
  height: 48px;
  margin: 0 20px;
`;

export const Date = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.colors.dateGrey};
  display: block;
  margin: 20px auto 10px auto;
`;

export const Name = styled(Date)`
  font-weight: bold;
  margin-top: 5px;
`;

export const FlexMessage = styled.div`
  display: flex;
  flex-direction: ${(props) => ('send' === props.type ? 'row' : 'row-reverse')};
`;
