const initialState = {
    id: null,
    messages: [],
};

const conversationReducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case 'SET_CONVERSATION_ID':
            return {
                ...state,
                id: payload.id,
            };
        case 'SET_MESSAGES':
            return {
                ...state,
                messages: payload.messages,
            };
        case 'ADD_MESSAGE':
            return {
                ...state,
                messages: [...state.messages, payload.messageData],
            };
        default:
            return state;
    }
};

export default conversationReducer;
