import styled from 'styled-components';
import { WhiteBox, Input } from '../../ui/style';

export const CategoryBox = styled(WhiteBox)`
  padding: 30px;
  margin: 40px 0;

  h3 {
    margin-top: 0;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin: 20px 0;

  p { margin: 0; }
`;

export const Thead = styled.thead`
  font-weight: bold;
  font-size: 1em;
`;

export const TheadRow = styled.tr`
  height: 40px;

  th:first-child {
    padding-left: 20px;
  }

  &:first-child {
    th:first-child {
      border-top-left-radius: 10px;
    }

    th:last-child {
      border-top-right-radius: 10px;
    }
  }

  &:last-child {
    th:first-child {
      border-bottom-left-radius: 10px;
    }

    th:last-child {
      border-bottom-right-radius: 10px;
    }
  }
`;

export const Th = styled.th`
  border: solid 1px ${({ theme }) => theme.colors.whiteSmoke};
  background-color: ${({ theme }) => theme.colors.whiteSmoke};

  &.text-left {
    text-align: left;
  }

  &.status {
    width: 130px;
  }

  &.actions {
    width: 140px;
  }
`;

export const Tbody = styled.tbody`
  tr:first-child {
    padding-top: 20px;
  }
`;

export const TbodyRow = styled.tr`
  height: 50px;

  td:first-child {
    padding-left: 20px;
  }
`;

export const Td = styled.td`
  line-height: 50px;

  &.row {
    display: flex;
    justify-content: space-between;
  }
`;

export const Actions = styled(Td)`
  display: flex;
  justify-content: flex-end;
`;

export const Status = styled.td`
  color: ${(props) => props.color};
  font-style: italic;
  text-align: center;
  padding: 0 20px;
`;

export const Length = styled.p`
  margin: 3px 0 0 10px !important;
  color: ${({ theme }) => theme.colors.metricGrey};
`;

export const Button = styled.button`
  background-color: ${(props) => ('validated' === props.status ? props.theme.colors.primaryPink : props.theme.colors.green)};
  border-radius: 5px;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 75px;

  span {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const AbstractButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;

  img {
    height: 20px;
    width: 20px;
  }
`;

export const EditButton = styled(AbstractButton)`
  height: 50px;
`;

export const SaveButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primaryPink};
  margin: auto 0 auto 15px;
  width: auto;

  img {
    height: 20px;
    width: 20px;
  }
`;

export const EditInput = styled(Input)`
  height: 25px;
  width: 100%;
  margin: auto 0;
`;

export const AddButton = styled.button`
  border: none;
  background-color: transparent;
  width: 250px;
  padding: 0;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.colors.primaryPink};
    text-decoration: underline;
    font-weight: bold;
    display: block;
    margin: auto 0;
  }

  img {
    margin-right: 10px;
  }
`;

export const CancelButton = styled(AddButton)`
  width: 75px;
`;

export const DeleteButton = styled(AbstractButton)`
  margin-right: 0;

  &.delete {
    z-index: 300;
  }

  img {
    margin-left: 10px;
  }
`;

export const Label = styled.p`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
