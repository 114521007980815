const initialState = {
    documents: [],
    documentsMetrics: null,
    dossiers: [],
    selectedDossier: null,
    files: [],
};

const paperworkReducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case 'SET_DOSSIERS_LIST':
            return {
                ...state,
                dossiers: payload.dossiers,
            };
        case 'SET_SELECTED_DOSSIER':
            return {
                ...state,
                selectedDossier: payload.selectedDossier,
            };
        case 'SET_SELECTED_DOSSIER_DOCUMENTS':
            return {
                ...state,
                documents: payload.documents,
            };
        case 'SET_SELECTED_DOSSIER_DOCUMENTS_METRICS':
            return {
                ...state,
                documentsMetrics: payload.metrics,
            };
        case 'SET_SELECTED_DOSSIER_UPLOADED_FILES':
            return {
                ...state,
                files: payload.files,
            };
        default:
            return state;
    }
};

export default paperworkReducer;
