/* eslint-disable import/prefer-default-export */
import Dropdown from 'react-dropdown';
import styled from 'styled-components';

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.black};
  font-weight: bold;
`;

export const Text = styled.h3`
  color: ${(props) => props.theme.colors.black};
  font-weight: normal;
`;

export const Input = styled.input`
  width: 302px;
  height: 43px;
  border-radius: 7px;
  border: solid 1px ${(props) => props.theme.colors.blueGrey};
  padding: 0 10px;
`;

export const TextArea = styled.textarea`
  width: 302px;
  border-radius: 7px;
  border: solid 1px ${(props) => props.theme.colors.blueGrey};
  padding: 10px;
  font-size: 1em;
  font-family: 'Open Sans', sans-serif;
  max-height: 90px;
`;

export const PrimaryButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  margin: auto;
  background-color: ${({ theme }) => theme.colors.primaryPink};
  height: 64px;
  border-radius: 15px;
  border: none;
  cursor: pointer;

  p {
    line-height: 64px;
    font-size: 1.6em;
    font-weight: 600;
    margin: 0 37px;
    text-align: center;
  }
`;

export const SmallPrimaryButton = styled(PrimaryButton)`
  margin: 0;
  height: 43px;
  border-radius: 7px;

  span {
    line-height: 43px;
    font-size: 1.3em;
    margin: 0 23px;
    font-weight: bold;
  }
`;

export const TransparentButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 1px;
`;

export const Space = styled.div`
  height: ${(props) => props.px}px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SpacedRow = styled(Row)`
  justify-content: space-between;
  align-items: baseline;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabsContainer = styled(Row)`
  width: 266px;
  border-bottom: 2px solid ${(props) => props.theme.colors.lightGrey};
`;

export const Tab = styled(Row)`
  position: relative;
  top: 3px;
  width: 50%;
  border-bottom: ${(props) => (props.isActive ? `4px solid ${props.theme.colors.black}` : 'none')};
  cursor: pointer;

  h3 {
    font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  }
`;

export const StyledDropdown = styled(Dropdown)`
  width: 500px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  z-index: 1;

  .Dropdown-control {
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    height: 30px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.white};

    .Dropdown-placeholder {
      color: ${(props) => props.theme.colors.blueGrey};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px 15px;
    }
  }

  .Dropdown-menu {
    background-color: ${(props) => props.theme.colors.white};
    height: auto;
    max-height: 555px;
    overflow-y: scroll;
    padding: 0 15px 15px 15px;

    .Dropdown-option {
      color: ${(props) => props.theme.colors.blueGrey};
      padding-top: 10px;
    }

    .Dropdown-option:hover {
      color: ${(props) => props.theme.colors.black};
    }
  }

  .Dropdown-arrow-wrapper {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid ${(props) => props.theme.colors.blueGrey};
    position: relative;
    bottom: 15px;
    left: 470px;
  }
`;

export const WhiteBox = styled.div`
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 ${(props) => props.theme.colors.shadowGrey};
  background-color: ${(props) => props.theme.colors.white};
`;

export const Spinner = styled.span`
  @keyframes spinner {
    to { transform: rotate(360deg); }
  }

  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    margin-top: 7px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.white};
    border-top-color: ${({ theme }) => theme.colors.primaryPink};
    animation: spinner 0.7s linear infinite;
  }
`;

export const FileSpinner = styled(Spinner)`
  &::before {
    margin-top: 4px;
    margin-left: -22px;
    width: 20px;
    height: 20px;
    border: 2px solid ${({ theme }) => theme.colors.iconGrey};
    border-top-color: ${({ theme }) => theme.colors.white};
  }
`;

export const Punchline = styled.p`
  display: block;
  margin-top: ${(props) => !props.chat && 'auto'};
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.blueGrey};
`;
