/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/browser';
import { GET } from '../utils/http';

const setDossiersList = (list) => ({
    type: 'SET_DOSSIERS_LIST',
    dossiers: list,
});

export const getAllDossiers = () => async (dispatch) => {
    try {
        const dossiersList = await GET('/api/paperwork/getAllDossiers');
        await dispatch(setDossiersList(dossiersList));
    } catch (e) {
        Sentry.captureException(e);
        console.warn(e);
    }
};

export const getAssignedDossiers = (userId) => async (dispatch) => {
    try {
        const assignedDossiers = await GET(`/api/paperwork/${userId}/assignedDossiers`);
        await dispatch(setDossiersList(assignedDossiers));
    } catch (e) {
        Sentry.captureException(e);
        console.warn(e);
    }
};

export const setSelectedDossier = (selectedDossier) => ({
    type: 'SET_SELECTED_DOSSIER',
    selectedDossier,
});
