/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/browser';
import { GET } from '../utils/http';

const setDossierUploadedFiles = (files) => ({
    type: 'SET_DOSSIER_UPLOADED_FILES',
    files,
});

const setSelectedDossierUploadedFiles = (files) => ({
    type: 'SET_SELECTED_DOSSIER_UPLOADED_FILES',
    files,
});

export const getUploadedFiles = (dossierId, role) => async (dispatch) => {
    try {
        const uploadedFiles = await GET(`/api/dossier/${dossierId}/file`);
        if ('client' === role) {
            dispatch(setDossierUploadedFiles(uploadedFiles));
        } else {
            dispatch(setSelectedDossierUploadedFiles(uploadedFiles));
        }
    } catch (e) {
        Sentry.captureException(e);
        console.warn(e);
    }
};
