/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

const Page = styled.div`
  width: 50vw;
  margin: 100px auto;

  h2,
  h3 {
    text-align: center;
    margin: 30px 0;
  }

  h1 {
    color: ${({ theme }) => theme.colors.primaryPink};
    text-align: center;
    margin: 100px 0;
  }

  h3 {
    color: ${({ theme }) => theme.colors.blueGrey};
  }

  p {
    line-height: 1.5;
  }
`;

const Tos = () => (
    <Page>
        <h1>Mentions Légales - Conditions Générales d&apos;utilisation - RGPD</h1>
        <h2>A. Mentions légales</h2>
        <h3>1. Informations concernant la société</h3>
        <p>
            Diviz.fr est une marque Atlantique Crédits<br />
            SARL au capital de 7 500 euros<br />
            37b la Voirie 85170 BELLEVIGNY<br />
            RCS LA ROCHE SUR YON 788 927 937
        </p>
        <p>
            Mandataire Non exclusif en Opérations de banques et en Services de paiement et Mandataire d’intermédiaire en Assurance immatriculé et Courtier en assurance et réassurance sous le n°12069010 (www.orias.fr).<br />
            RC Pro AMLIN N°2012PCA138<br />
            Adhérent de l’AFIB : Association Française des Intermédiaires Bancaires
        </p>
        <p>N° Téléphone : 02 28 97 71 63</p>
        <p>Adresse de l’agence : 25bd d’Italie 85000 LA ROCHE SUR YON</p>
        <p>Directeur de Publication : Monsieur Adrien VILLAIN</p>
        <p>Webmaster : Diviz.fr/Atlantique Crédits</p>
        <p>
            Hébergement et infogérance :<br />
            OVH, 2 rue Kellermann - 59100 Roubaix - France<br />
        </p>
        <a href="https://ovh.com/fr">www.ovh.com/fr</a>
        <p>
            Le présent site internet est régi par le droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de la Roche Sur Yon.
        </p>
        <h3>2. Informations sur l&apos;activité d&apos;intermédiaire</h3>
        <p>
            En qualité de Mandataire Non Exclusif en Opérations de Banque et de Mandataire d’Intermédiaire en Assurance, Atlantique Crédits est soumise au contrôle de l&apos;Autorité de Contrôle Prudentiel et de Résolution (ACPR), sise 4 Place de Budapast, 75436 Paris -<br /> téléphone : 01 49 95 40 00 - site : acpr.banque-france.fr.
        </p>
        <p>Diviz.fr via Atlantique Crédits est mandatée non exclusivement par :</p>
        <ul>
            <li>MY MONEY BANK 20 avenue André-Prothin Tour Europlaza 92063 PARIS LA DEFENSE ;</li>
            <li>CREATIS 61 avenue Halley - Parc de la Haute Borne - 59650 VILLENEUVE D&apos;ASCQ ;</li>
            <li>CFCAL 1 rue du Dôme 67003 STRASBOURG ;</li>
            <li>SYGMA, marque commerciale de BNP Paribas Personal Finance, 1 Bld Haussmann 75318 Paris CEDEX 09 ;</li>
        </ul>
        <p>
            UN CRÉDIT VOUS ENGAGE ET DOIT ÊTRE REMBOURSÉ. VÉRIFIEZ VOS CAPACITÉS DE REMBOURSEMENT AVANT DE VOUS ENGAGER. Aucun versement de quelque nature que ce soit ne peut être exigé d’un particulier avant l’obtention d’un ou plusieurs prêts d’argent<br /> (L 519-6 du Code Monétaire et Financier).
        </p>
        <p>
            Pour un financement soumis au régime du Crédit à la consommation, vous disposez d’un délai de rétractation de 14 jours à compter de la signature de l’offre de prêt pour revenir sur votre engagement.
            Pour un financement soumis au régime du Crédit immobilier, vous disposez d’un délai de réflexion de 10 jours à compter de la réception de l’offre de prêt. Si la vente est subordonnée à l&apos;obtention du prêt et que celui-ci n&apos;est pas obtenu, le vendeur doit rembourser à l&apos;emprunteur les sommes versées. Pour un financement relevant du régime du crédit immobilier, nous pouvons vous fournir, à votre demande, les informations générales sur la gamme des produits proposés par nos partenaires bancaires.
        </p>
        <p>
            La Convention AERAS vous concerne si votre état de santé ne vous permet pas d’obtenir une couverture d’assurance aux conditions standard :
        </p>
        <a href="http://www.aeras-infos.fr">www.aeras-infos.fr</a>
        <h2>B. Conditions générales d’Utilisations / Espace Information et Espace Utilisateur</h2>
        <h3>1. Description des services fournis</h3>
        <p>
            Le site Diviz.fr de la société Atlantique Crédits a pour objet de fournir une information concernant l’ensemble des activités de la société.
            La société Atlantique Crédits s’efforce de fournir sur le site de la société des informations aussi précises que possible. Toutefois, elle ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.<br />
            Tous les informations indiquées sur le site de la société Diviz.fr par Atlantique Crédits sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le site de la société ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Si néanmoins vous trouviez une information incorrecte ou erronée dans nos pages, merci de nous avertir (via le formulaire de contact par exemple).
        </p>
        <h3>2. Limitations contractuelles sur les données techniques / Cookies</h3>
        <p>
            Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis à jour. Ce site peut contenir des liens vers d&apos;autres sites web. Atlantique Crédits n&apos;assume aucune responsabilité sur le contenu des sites autres que ceux faisant partie du domaine https://diviz.fr.<br />
            La navigation sur le site diviz.fr de la société Atlantique Crédits est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.
        </p>
        <p>
            Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l’installation des cookies :
            Sous Internet Explorer : onglet outil / options internet.
            Cliquez sur Confidentialité et choisissez Bloquer tous les cookies.
            Validez sur OK.
        </p>
        <h3>3. Propriété intellectuelle et contrefaçons</h3>
        <p>
            La société Atlantique Crédits est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site diviz.fr, notamment les textes, images, graphismes, logos, icônes, sons, logiciels.<br />
            Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de la société Atlantique Crédits.<br />
            Toute exploitation non autorisée du site ou d’un quelconque élément qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
        </p>
        <h3>4. Limitations de responsabilité</h3>
        <p>
            La société Atlantique crédits ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site diviz.fr, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 2, soit de l’apparition d’un bug ou d’une incompatibilité. La société Atlantique Crédits  ne pourra également être tenue responsable des dommages indirects (tels que par exemple une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site diviz.fr.
        </p>
        <p>
            Des espaces intéractifs (possibilité de poser des questions dans l’espace contact) sont à la disposition des utilisateurs. La société Atlantique Crédits se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données. Le cas échéant, la société Atlantique Crédits se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…).
        </p>
        <h2>C. Données personnelles /RGPD</h2>
        <h3>1. Charte Vie Privée</h3>
        <p>
            Les données à caractère personnel recueillies à l&apos;occasion des demandes de contact, d’information, de rappel ou de candidature sur le site https://diviz.fr, hébergé sur les serveurs de OVH, font l’objet de traitements informatiques déclarés à la Commission Nationale de l&apos;Informatique et des Libertés sous le numéro 1827897v0​.
        </p>
        <p>
            Ces données sont traitées par le responsable du site https://diviz.fr, VILLAIN Adrien, gérant de la société ATLANTIQUE CREDITS destinataire des données issues des demandes de contact et commerciales, pour que ce dernier puisse répondre aux utilisateurs, constituer des propositions commerciales chiffrées sur le fondement juridique de l&apos;exécution d&apos;un contrat. Les données issues des candidatures sont traitées par Atlantique Crédits pour que ce dernier puisse apporter des réponses aux candidatures.
        </p>
        <p>
            Les données ne seront jamais utilisées à des fins autres, non prévues, ni transmises à des partenaires, sauf accord de la part du propriétaire des données. Aucune information personnelle autre que celles demandées dans ce cadre n’est collectée à l&apos;insu des personnes concernées. Les données collectées peuvent également être recopiées pour alimenter un fichier clients déclaré de la même manière que précédemment à la Commission Nationale de l&apos;Informatique et des Libertés.
        </p>
        <p>
            Il est aimablement rappelé aux utilisateurs du site que l&apos;exécution d&apos;un contrat de vente de prestation de service et la livraison finale d&apos;un site internet sont conditionnées à la fourniture des données à caractère personnel. L&apos;utilisateur souhaitant conclure un contrat de vente avec le site https://diviz.fr doit être conscient que la non-fourniture de ces données entraîne l&apos;impossibilité de poursuivre le processus contractuel.
        </p>
        <p>
            Également, les données transitant par le site font l&apos;objet d&apos;une attention de tous les instants quant à leur sécurité et leur confidentialité. Outre un site web en HTTPS, l&apos;accès à l&apos;arrière-guichet (back-office) du site est possible via des logins et des mots de passe complexes à protection forte ainsi que d’une protection avec double authentification. Il est en de même pour la boîte mail réceptacle des données personnelles des utilisateurs et clients.
        </p>
        <p>
            Également, le site web https://diviz.fr est équipé de nombreux modules de sécurité empêchant au maximum les intrusions malveillantes et les fuites de données.
        </p>
        <p>
            Cependant, l&apos;utilisateur de ce site ou le client peut à tout moment introduire une réclamation auprès de la Commission Nationale Informatique et Libertés (CNIL), autorité de contrôle française.
        </p>
        <p>
            Vous disposez d’un droit d’accès, de rectification, de limitation, d’opposition et d’effacement sur vos données, ainsi que leur portabilité. Ces demandes doivent être adressées au Délégué à la protection des données personnels désigné :
        </p>
        <p mailto="adrien@diviz.fr">adrien@diviz.fr</p>
        <p>
            Vous pouvez, à tout moment, vous opposer à la communication de vos données à des tiers ou à leur utilisation à des fins commerciales dans les conditions ci-dessus-indiquées.
        </p>
        <h3>2. RGPD</h3>
        <p>
            La présente « Politique de Protection des Données Personnelles » a pour objet de vous informer sur la manière dont nous collectons et utilisons vos données personnelles et sur les moyens dont vous disposez pour contrôler cette utilisation.<br />
            La protection de vos données personnelles revêt une grande importance pour Diviz.fr/Atlantique Crédits.<br />
            Engagés dans une démarche continue de protection des données personnelles de nos clients, même potentiels, nous tenons à adopter une attitude ouverte et transparente dans le cadre de leur traitement.<br />
            Quelques définitions :<br />
            Le terme « données à caractère personnel » recouvre toute information se rapportant à une personne physique identifiée ou identifiable (ci-après dénommée «personne concernée») ;<br />
            Constitue un «traitement» toute opération ou tout ensemble d’opérations et appliquées à des données ou des ensembles de données à caractère personnel, telles que la collecte, l’enregistrement, l’organisation, la structuration, la conservation, l’adaptation ou la modification, l’extraction, la consultation, l’utilisation, la communication par transmission, la diffusion ou toute autre forme de mise à disposition, le rapprochement ou l’interconnexion, la limitation, l’effacement ou la destruction;<br />
            Le terme «responsable du traitement» désigne la personne physique ou morale qui, seule ou conjointement avec d’autres, détermine les finalités et les moyens du traitement;<br />
        </p>
        <ul>
            <li><h4>Qui est responsable du traitement de vos données ?</h4></li>
        </ul>
        <p>
            Atlantique Crédits est responsable du traitement de vos données. Cela signifie qu&apos;elle détermine les finalités pour lesquelles les données sont collectées.<br />
            De ce fait, Atlantique Crédits traite et protèges vos données dans le respect de la loi et de façon loyale et transparente.<br />
        </p>
        <ul>
            <li><h4>En quoi consiste le traitement des données personnelles ?</h4></li>
        </ul>
        <p>
            Les données personnelles comprennent toutes les informations relatives à une personne physique identifiée ou qui peut être identifiée, directement ou indirectement concernant une personne par référence à un ou plusieurs éléments qui lui sont propres.<br />
            Le traitement des données personnelles consiste en toute opération ou tout ensemble d’opérations portant sur de telles données, quel que soit le procédé utilisé, et notamment la collecte, l’enregistrement, l’organisation, la conservation.<br />
            A travers ses différents services, et notamment pour répondre à ses obligations d’Intermédiaire en Opérations de Banque et en Services de Paiement et d’Intermédiaire en Assurance, Atlantique Crédits collecte et traite des renseignements, données et informations de toute nature, fournis par vous ou par des tiers, dans la mesure nécessaire ou utile pour l&apos;exercice de son activité. Si vous n’avez aucune obligation légale de nous transmettre vos données personnelles, sachez qu’en cas de refus, cela peut entraîner pour Atlantique Crédits l’impossibilité d’entrer en relation ou de poursuivre cette relation avec vous, et compromettre l’étude de vos demandes.
        </p>
        <ul>
            <li><h4>Quelles données personnelles sont traitées ?</h4></li>
        </ul>
        <p>
            Atlantique Crédits peut être amenée à traiter des renseignements, données et informations de toute nature, fournis par vous ou par des tiers, dans la mesure nécessaire ou utile pour l&apos;exercice de son activité de Mandataire Non Exclusif en Opérations de Banque et en Services de Paiement, et d’Intermédiaire en Assurance.
        </p>
        <p>Les données personnelles traitées sont :</p>
        <ul>
            <li>Les données d&apos;identification telles que vos nom et prénom, adresse, date et lieu de naissance, adresse e-mail, etc ;</li>
            <li>Les données socio démographiques telles que votre situation familiale, état civil, emploi, etc ;</li>
            <li>Les données relatives à vos ressources et votre patrimoine ;</li>
            <li>Les données financières et transactionnelles telles que vos relevés de compte, le paiement de vos crédits, etc ;</li>
            <li>Les données liées à votre connaissance et à votre expérience en matière de crédit et d&apos;assurance ;</li>
            <li>Les données relatives à vos comportements et habitudes de navigation sur notre site et Espace client : consultation d&apos;informations, de simulations, de demandes, de réactions à nos propositions commerciales, etc ;</li>
            <li>Les données concernant nos enquêtes de satisfaction ;</li>
            <li>Les données obtenues via des tiers, par exemple votre courtier, votre conseil juridique, etc ;</li>
            <li>Les données recueillies via des cookies (les cookies sont essentiels au bon fonctionnement de notre site).</li>
            <li>Les données recueillies via des trackers (les trackers servent à retenir vos préférences, collecter des données statistiques, adapter le contenu et/ou la publicité des sites selon vos besoins).</li>
        </ul>
        <p>
            Atlantique Crédits ne traite pas de données sensibles telles que les données santé, les données à caractère racial ou ethnique, politique philosophique ou religieux, ni celles relatives à l&apos;appartenance syndicale ou à la vie sexuelle.
        </p>
        <ul>
            <li><h4>Quand collectons-nous vos données ?</h4></li>
        </ul>
        <p>
            Nous collectons vos données lorsque vous entrez en contact avec Atlantique Crédits / Diviz.fr et à chaque étape du processus du montage de votre dossier, par exemple lorsque :
        </p>
        <ul>
            <li>Vous complétez notre formulaire de demande de simulation en ligne (sur notre site internet ou sur un site internet affilié).</li>
            <li>Vous contactez une de nos agences directement par téléphone.</li>
            <li>Vous répondez positivement à notre simulation et vous souhaitez constituer et déposer un dossier auprès d&apos;une de nos banques partenaires.</li>
        </ul>
        <ul>
            <li><h4>Pourquoi Atlantique Crédits traite-t-elle vos données ?</h4></li>
        </ul>
        <p>
            Tout d&apos;abord, Atlantique Crédits traite vos données car elle est tenue par de nombreuses obligations légales et réglementaires liées à son statut d&apos;Intermédiaire en Opérations de Banque et en Services de Paiement et d&apos;Intermédiaire en Assurances (ex : analyse de la situation financière du client et les conséquences de la souscription du crédit sur sa situation, obligation de prévenir et lutter contre le blanchiment de capitaux et le financement du terrorisme, obligation de répondre à toute demande officielle des Autorités publiques compétentes, etc).
            Atlantique Crédits traite également vos données pour les besoins des relations contractuelles avec vous en tant que Client (liste non exhaustive) :
        </p>
        <ul>
            <li>Répondre à vos demandes de simulation et, le cas échéant, vous signaler l&apos;impossibilité d&apos;intervention de nos partenaires bancaires ;</li>
            <li>Vous connecter à notre site et accéder à nos services dans le cadre de la relation contractuelle (ex : Espace personnel) ;</li>
            <li>La gestion de votre dossier : vous accompagner dans votre projet en évaluant l&apos;opportunité et les risques liés à l’éventuelle souscription d&apos;un crédit ; déterminer les conditions et garanties de votre projet ; répondre aux demandes d&apos;informations complémentaires de la banque auprès de laquelle votre dossier est présenté.</li>
            <li>Participer à nos enquêtes satisfactions ;</li>
            <li>L&apos;amélioration de nos services ;</li>
            <li>Le marketing direct afin de vous adresser des informations, des publicités ou des propositions concernant des produits ou des services pertinents pour vous.</li>
        </ul>
        <ul>
            <li><h4>À qui vos données peuvent-elles être transférées ?</h4></li>
        </ul>
        <p>
            Vos données sont traitées de manière confidentielle. Les salariés de Atlantique Crédits sont tenus par le secret professionnel qui fait l&apos;objet d&apos;une clause spécifique inscrite dans leur contrat de travail.
        </p>
        <ul>
            <li>Vos données sont transmises auprès de nos partenaires bancaires et assureurs susceptibles d&apos;intervenir dans votre projet : My Money Bank 20 avenue André-Prothin Tour Europlaza 92063 PARIS LA DEFENSE ; CFCAL, 1 rue du dôme 67000 STRASBOURG ; Créatis 61 avenue Halley 59650 Villeneuve d’Ascq ; BNP PARIBAS PERSONAL FINANCE 1 Bld Haussmann 75009 PARIS ;</li>
            <li>En crédit hypothécaire, Atlantique Crédits transmettra également ces données au Notaire en vue de la gestion des garanties, du déblocage des fonds et, le cas échéant, du paiement des honoraires de Atlantique Crédits.</li>
            <li>Pour satisfaire à nos obligations légales, Atlantique Crédits est susceptible de partager certaines données personnelles avec les autorités judiciaires, l&apos;Autorité de Contrôle Prudentiel et de Résolution, la Commission Nationale Informatique et Libertés, la Direction Générale de la Concurrence, de la Consommation et de la Répression des Fraudes, etc.</li>
        </ul>
        <p>
            Atlantique Crédits conserve vos données uniquement au sein de l&apos;Espace Economique Européen.
        </p>
        <ul>
            <li><h4>Quelle est la durée de conservation de vos données ?</h4></li>
        </ul>
        <p>
            La durée de conservation de vos données n&apos;excède pas celle nécessaire au traitement pour lequel elles ont été collectées, sauf dans le cas où la loi ou le règlement en prévoient la durée.
            Les données collectées sont conservées pour une durée comprise entre 2 et 5 ans selon le type de financement. Au-delà de cette durée, vos données font l&apos;objet d&apos;un archivage afin de répondre aux obligations légales ainsi qu&apos;à la nécessité juridique de conserver vos données à des fins de preuve ou pour répondre à des demandes d&apos;information des autorités compétentes.
        </p>
        <ul>
            <li><h4>Quels sont vos droits et comment les exercer ?</h4></li>
        </ul>
        <ul>
            <li><h5>Droit général d&apos;opposition au marketing direct </h5></li>
        </ul>
        <p>
            Vous pouvez à tout moment vous opposer à l&apos;utilisation des données que nous collectons, conservons et traitons à des fins de marketing directement par  mail : adrien@diviz.fr, ou par courrier  adressé à Diviz.fr/Atlantique Crédits 37b la Voirie 85170 Bellevigny.
        </p>
        <ul>
            <li>
                <h5>Droit d&apos;accès, de rectification, de suppression et de portabilité des données </h5>
            </li>
        </ul>
        <p>
            Vous disposez d&apos;un droit d&apos;accès permanent à vos données personnelles et dans ce cadre, vous pouvez demander les informations suivantes :
        </p>
        <ul>
            <li>es catégories de données personnelles en notre possession ;</li>
            <li>les finalités du traitement ;</li>
            <li>les destinataires ou les catégories de destinataires ;</li>
        </ul>
        <p>
            Si  vos  données  personnelles  sont  inexactes,  non  pertinentes  ou  incomplètes,  nous  les  modifierons,  les complèterons et, dans certains cas précis, les supprimerons dans la mesure permise par la loi. Vous pouvez aussi demander que certaines de ces données vous soient directement transmises ou à une tierce partie.<br />
            Merci de nous préciser votre demande directement par  mail : adrien@diviz.fr, ou par courrier  adressé à Diviz.fr/Atlantique Crédits 37b la Voirie 85170 Bellevigny.
        </p>
        <ul>
            <li>
                <h5>Le traitement automatisé de vos données et la faisabilité de votre projet</h5>
            </li>
        </ul>
        <p>
            Les chargés de clientèle Atlantique Crédits examinent en principe toutes les demandes de simulation.  Votre demande peut toutefois être refusée sur base d&apos;un traitement automatique.  Vous en serez alors avisé.
        </p>
        <ul>
            <li>
                <h5>Commission Nationale Informatique et Libertés</h5>
            </li>
        </ul>
        <p>
            Si nous n&apos;avons pas pu vous donner satisfaction, vous pouvez contacter la CNIL, 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07 - Tél : 01 53 73 22 22 (du lundi au jeudi de 9h à 18h30 / le vendredi de 9h à 18h) - Fax : 01 53 73 22 00 – site internet :
        </p>
        <a href="https://cnil.fr"> www.cnil.fr</a>

        <h2>D. Services clients et réclamations</h2>
        <h3>1. Service client</h3>
        <p>
            Dans le cadre de la relation commerciale avec nos services (informations client et client potentiel à l&apos;entrée en relation), toute réclamation doit être formulée à Atlantique Crédits – 37b la Voirie – 85170 BELLEVIGNY ou par mail : adrien@diviz.fr.
        </p>
        <h3>2. Médiation à la consommation </h3>
        <p>
            À la suite d&apos;une réclamation auprès de notre Service Clients pour laquelle vous n&apos;avez pas reçu de réponse satisfaisante, vous pouvez saisir gratuitement un Médiateur de la consommation : IEAM Médiation - Contact par voie électronique via le site internet :
        </p>
        <a href="https://ieam.eu"> www.ieam.eu</a>
        <p>
            Contact par courrier à l’adresse suivante : IEAM Mediation/AFIB – 31b et 33 rue Dahu – 75 008 PARIS
        </p>
    </Page>
);

export default Tos;
