/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/browser';
import { GET } from '../utils/http';

const setConversationId = (id) => ({
    type: 'SET_CONVERSATION_ID',
    ...id,
});

const setMessages = (messages) => ({
    type: 'SET_MESSAGES',
    messages,
});

export const addMessage = (messageData) => ({
    type: 'ADD_MESSAGE',
    messageData,
});

export const getConversationData = (dossierId) => async (dispatch) => {
    try {
        const conversationData = await GET(`/api/dossier/${dossierId}/conversation`);
        await dispatch(setConversationId(conversationData));
    } catch (e) {
        Sentry.captureException(e);
        console.warn(e);
    }
};

export const getAllMessages = (conversationId) => async (dispatch) => {
    try {
        const messages = await GET(`/api/conversation/${conversationId}/messages`);
        await dispatch(setMessages(messages));
    } catch (e) {
        Sentry.captureException(e);
        console.warn(e);
    }
};
