/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/browser';
import { POST } from '../utils/http';

export const uploadFilePending = (file) => ({
    type: 'UPLOAD_FILE_PENDING',
    name: file.name,
});

export const uploadFileSuccess = (file) => ({
    type: 'UPLOAD_FILE_SUCCESS',
    name: file.name,
});

export const uploadFileError = (file, error) => ({
    type: 'UPLOAD_FILE_ERROR',
    name: file.name,
    error,
});

export const emptyFileStatusList = () => ({
    type: 'EMPTY_FILE_STATUS_LIST',
});

export const uploadFile = (userId, dossierId, file, importDate) => async (dispatch) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('userId', userId);
    formData.append('importDate', importDate);
    try {
        await dispatch(uploadFilePending(file));
        await POST(`/api/dossier/${dossierId}/file/upload`, formData);
        await dispatch(uploadFileSuccess(file));
    } catch (error) {
        Sentry.captureException(error);
        await dispatch(uploadFileError(file, error));
    }
};
