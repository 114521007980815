import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createDocument } from '../../actions/document';
import { getDossierDocuments } from '../../actions/dossier';
import { Row } from '../../ui/style';
import { CancelButton, EditInput, SaveButton } from './style';

const CreationInput = ({ categoryId, openCreationMode, dossierId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [documentName, setDocumentName] = useState();

    const saveInput = (e) => {
        setDocumentName(e.target.value);
    };

    const sendDocument = async () => {
        if (documentName) {
            await dispatch(createDocument(dossierId, categoryId, documentName));
            await dispatch(getDossierDocuments(dossierId, 'advisor'));
            openCreationMode(false);
        }
    };

    return (
        <Row>
            <EditInput
                onChange={saveInput}
                onKeyPress={(e) => { if (13 === e.charCode) { sendDocument(); } }}
                placeholder={t('documents.create.placeholder')}
                type="text"
            />
            <SaveButton onClick={sendDocument} type="button">
                <span>
                    {t('documents.document.add')}
                </span>
            </SaveButton>
            <CancelButton onClick={() => openCreationMode(false)} style={{ marginLeft: '10px' }} type="button">
                <span>
                    {t('documents.create.cancel')}
                </span>
            </CancelButton>
        </Row>
    );
};

CreationInput.propTypes = {
    categoryId: PropTypes.string.isRequired,
    dossierId: PropTypes.string.isRequired,
    openCreationMode: PropTypes.func.isRequired,
};

export default CreationInput;
