/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/browser';
import { GET } from '../utils/http';

const setDossier = (dossierAdvisors) => ({
    type: 'SET_DOSSIER',
    ...dossierAdvisors,
});

const setDossierDocuments = (documents) => ({
    type: 'SET_DOSSIER_DOCUMENTS',
    documents,
});

const setSelectedDossierDocuments = (documents) => ({
    type: 'SET_SELECTED_DOSSIER_DOCUMENTS',
    documents,
});

const setDossierInformations = (informations) => ({
    type: 'SET_DOSSIER_INFORMATIONS',
    informations,
});

export const getDossierAdvisors = (dossierId) => async (dispatch) => {
    try {
        const dossierAdvisors = await GET(`/api/dossier/${dossierId}/advisors`);
        await dispatch(setDossier(dossierAdvisors));
    } catch (e) {
        Sentry.captureException(e);
        console.warn(e);
    }
};

export const getDossierDocuments = (dossierId, role) => async (dispatch) => {
    try {
        const documents = await GET(`/api/dossier/${dossierId}/documents`);
        if ('client' === role) {
            await dispatch(setDossierDocuments(documents));
        } else {
            await dispatch(setSelectedDossierDocuments(documents));
        }
    } catch (e) {
        Sentry.captureException(e);
        console.warn(e);
    }
};

export const getDossierInformations = (dossierId) => async (dispatch) => {
    try {
        const dossierInformations = await GET(`/api/dossier/${dossierId}/informations`);
        dispatch(setDossierInformations(dossierInformations));
    } catch (e) {
        Sentry.captureException(e);
        console.warn(e);
    }
};
