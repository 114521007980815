const initialState = {
    filesStatus: [],
};

const uploadReducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case 'EMPTY_FILE_STATUS_LIST':
            return initialState;
        case 'UPLOAD_FILE_PENDING':
            return {
                filesStatus: [
                    ...state.filesStatus,
                    { name: payload.name, status: 'pending' },
                ],
            };
        case 'UPLOAD_FILE_SUCCESS':
            return {
                filesStatus: state.filesStatus.map(({ name, status }) => ({
                    name,
                    status: name === payload.name ? 'uploaded' : status,
                })),
            };
        case 'UPLOAD_FILE_ERROR':
            return {
                filesStatus: state.filesStatus.map((fileStatus) => {
                    if (fileStatus.name === payload.name) {
                        return {
                            name: fileStatus.name,
                            status: 'error',
                            error: payload.error,
                        };
                    }

                    return fileStatus;
                }),
            };
        default:
            return state;
    }
};

export default uploadReducer;
