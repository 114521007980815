import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Row, Title } from '../../ui/style';
import Logo from '../../assets/diviz-logo.png';
import warnIcon from '../../assets/icons/warning.svg';
import LoginImage from '../../assets/login-image.png';

const MainContainer = styled(Row)`
  overflow-y: hidden;
`;

const LeftContainer = styled.div`
  width: 50%;
  padding: 32px 0 0 56px;

  img {
    width: 230px;
  }

  h4 {
    width: 250px;
    color: ${({ theme }) => theme.colors.metricGrey};
    font-weight: normal;
  }
`;

const FormContainer = styled.div`
  padding: 20px 0 0 92px;

  @media screen and (max-width: 900px) {
    padding: 0;
  }
`;

const LoginImg = styled.img`
  width: 50%;
  position: fixed;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const WarnBlock = styled.div`
  display: flex;
  flex-direction: column;

  p {
    color: ${({ theme }) => theme.colors.primaryPink};
    font-weight: bold;
  }

  img {
    width: 34px;
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
`;

const LoginLayout = ({ children, title }) => {
    const { t } = useTranslation();

    return (
        <MainContainer>
            <LeftContainer>
                <img alt="diviz-logo" src={Logo} />
                <h4>{t('login.punchline')}</h4>
                <FormContainer>
                    <Title>
                        {title}
                    </Title>
                    <WarnBlock>
                        <img alt="warning" src={warnIcon} />
                        <p>{t('login.warn.message')}</p>
                    </WarnBlock>
                    {children}
                </FormContainer>
            </LeftContainer>
            <div>
                <LoginImg alt={t('login.image.people')} src={LoginImage} />
            </div>
        </MainContainer>
    );
};

LoginLayout.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
};

export default LoginLayout;
