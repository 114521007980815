/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/browser';
import { POST, GET } from '../utils/http';

export const changeValidationStatus = (documentId, validate) => async () => {
    try {
        await POST(`/api/document/${documentId}/validate`, { validate });
    } catch (e) {
        console.warn(e);
    }
};

export const updateDocumentName = (documentId, documentName) => async () => {
    try {
        await POST(`/api/document/${documentId}/updateName`, { name: documentName });
    } catch (e) {
        console.warn(e);
    }
};

export const createDocument = (dossierId, categoryId, documentName) => async () => {
    try {
        await POST(`/api/dossier/${dossierId}/document/create`, { name: documentName, categoryId });
    } catch (e) {
        console.warn(e);
    }
};

export const deleteDocument = (id) => async () => {
    try {
        await POST(`/api/dossier/:dossierId/document/${id}/delete`);
    } catch (e) {
        console.warn(e);
    }
};

const setDocumentsMetrics = (metrics) => ({
    type: 'SET_DOCUMENTS_METRICS',
    metrics,
});

const setSelectedDossierDocumentsMetrics = (metrics) => ({
    type: 'SET_SELECTED_DOSSIER_DOCUMENTS_METRICS',
    metrics,
});

export const getDocumentsMetrics = (dossierId, role) => async (dispatch) => {
    try {
        const metrics = await GET(`/api/dossier/${dossierId}/document/metrics`);
        if ('client' === role) {
            await dispatch(setDocumentsMetrics(metrics));
        } else {
            await dispatch(setSelectedDossierDocumentsMetrics(metrics));
        }
    } catch (e) {
        Sentry.captureException(e);
        console.warn(e);
    }
};
