import PropTypes from 'prop-types';
import React from 'react';
import { Avatar, FlexMessage, MessageBlock, Date, MessageBox, Name } from './style';
import defaultAvatar from '../../assets/avatar.svg';

const Message = ({ author, date, textMessage, type }) => (
    <MessageBlock type={type}>
        <Date>{date}</Date>
        <Name>{author.firstName} {author.lastName}</Name>
        <FlexMessage type={type}>
            <MessageBox style={{ whiteSpace: 'pre-line' }} type={type}>
                {textMessage}
            </MessageBox>
            <Avatar>
                <img alt="avatar" src={author.avatar || defaultAvatar} />
            </Avatar>
        </FlexMessage>
    </MessageBlock>
);

Message.propTypes = {
    author: PropTypes.shape({
        avatar: PropTypes.string,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
    }).isRequired,
    date: PropTypes.string.isRequired,
    textMessage: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default Message;
