import styled from 'styled-components';
import { Column, Row, WhiteBox } from '../../ui/style';

export const Sidebar = styled(Column)`
  align-items: center;
  width: 83px;
  height: 100vh;

  a {
    margin-left: 20px;
  }
`;

export const TabsContainer = styled.div`
  margin: 30vh auto 0 auto;
`;

export const Tab = styled(Column)`
  width: 83px;
  height: 60px;
  border-left: ${(props) => (props.isActive ? `2px solid ${props.theme.colors.black}` : 'none')};
  background-color: ${(props) => (props.isActive ? props.theme.colors.paleGrey : 'transparent')};
  margin: 30px 0;
  justify-content: center;
  cursor: pointer;

  img {
    display: block;
    margin: 0 auto;
  }
`;

export const TabText = styled.p`
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.black};
  text-align: center;
  margin: 0;
`;

export const ContentContainer = styled(Row)`
  background-color: ${(props) => props.theme.colors.backgroundBlue};
  width: 100%;
  padding: 65px 0 30px 65px;
  border-radius: 15px 0 0 15px;
`;

export const MainContainer = styled(Row)`
  background-color: ${(props) => props.theme.colors.whiteBlue};
`;

export const DocumentsContainer = styled(WhiteBox)`
  width: 20vw;
  height: 750px;
  position: fixed;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 50px;

  hr {
    color: ${({ theme }) => theme.colors.backgroundBlue};
    margin-top: 20px;
  }
`;

export const Paragraph = styled.p`
  margin-left: 10px;
  color: ${(props) => ('missing' === props.type ? props.theme.colors.primaryPink : props.theme.colors.metricGrey)};
`;

export const FilesBox = styled.div`
  height: 500px;
  overflow-y: auto;
`;
