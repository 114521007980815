import PropTypes from 'prop-types';
import React from 'react';
import { TransparentButton } from './style';

const IconButton = ({ children, onClick }) => (
    <TransparentButton onClick={onClick} type="button">
        {children}
    </TransparentButton>
);

IconButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default IconButton;
